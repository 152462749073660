import { defineNuxtPlugin } from '#app';
import { useStore } from '@/store/index';
import { useNuxtApp } from '#app';
import Me from '@/data/models/Me';

export function activateIntercom() {
    const nuxtApp = useNuxtApp();
    if (nuxtApp.$config.public.enableIntercom !== 'true') {
        return
    }

    // not enabled on login page
    const store = useStore();
    if (!store.isLoggedIn) {
        return
    }

    // already activated
    if (window.Intercom) {
        return
    }

    console.log('Init plugins/nodeup-intercom.js')

    var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/ekaafu79'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } }

    const meData = Me.getData()
    window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "ekaafu79",
        name: meData.name,
        email: meData.email,
        user_id: meData.id,
        hide_default_launcher: true,
        custom_launcher_selector: '.btn-launch-intercom-chat'
    });

    // runs after each page has finished loading
    nuxtApp.hook('page:finish', () => {
        if (typeof window.Intercom === "function") {
            window.Intercom("update");
        }
    });
}

export default defineNuxtPlugin(nuxtApp => {
    // store is not yet ready while page is loading
    // EDIT: currently loading intercom within afterLogin (runs on every page load), no need to run here aswell
    // setTimeout(activateIntercom, 4000);
});
