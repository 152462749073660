<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83997 8H18.16C18.6617 8.00002 19.145 8.18859 19.5142 8.5283C19.8833 8.86801 20.1113 9.33405 20.153 9.834L20.819 17.834C20.8419 18.1097 20.8075 18.3871 20.7177 18.6488C20.628 18.9104 20.4849 19.1507 20.2976 19.3542C20.1103 19.5578 19.8828 19.7203 19.6295 19.8314C19.3762 19.9426 19.1026 20 18.826 20H5.17397C4.89735 20 4.62373 19.9426 4.37042 19.8314C4.11711 19.7203 3.88961 19.5578 3.70231 19.3542C3.515 19.1507 3.37196 18.9104 3.28222 18.6488C3.19249 18.3871 3.15801 18.1097 3.18097 17.834L3.84697 9.834C3.88861 9.33405 4.11662 8.86801 4.48578 8.5283C4.85494 8.18859 5.33829 8.00002 5.83997 8ZM10.5 10C10.3674 10 10.2402 10.0527 10.1464 10.1464C10.0527 10.2402 9.99997 10.3674 9.99997 10.5V11.5C9.99997 11.6326 10.0527 11.7598 10.1464 11.8536C10.2402 11.9473 10.3674 12 10.5 12H13.5C13.6326 12 13.7598 11.9473 13.8535 11.8536C13.9473 11.7598 14 11.6326 14 11.5V10.5C14 10.3674 13.9473 10.2402 13.8535 10.1464C13.7598 10.0527 13.6326 10 13.5 10H10.5Z" fill="#66686D"/>
        <path d="M10 8H8V7C8 6.20435 8.31607 5.44129 8.87868 4.87868C9.44129 4.31607 10.2044 4 11 4H13C13.7956 4 14.5587 4.31607 15.1213 4.87868C15.6839 5.44129 16 6.20435 16 7V8H14V7C14 6.73478 13.8946 6.48043 13.7071 6.29289C13.5196 6.10536 13.2652 6 13 6H11C10.7348 6 10.4804 6.10536 10.2929 6.29289C10.1054 6.48043 10 6.73478 10 7V8Z" fill="#66686D"/>
    </svg>
</template>

<script>
export default {
  name: "iconBriefcase"
};
</script>
