<template>
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.04678 3.87462L8.34678 0.574619C8.40828 0.510946 8.48184 0.460158 8.56317 0.425218C8.64451 0.390279 8.73199 0.371888 8.82051 0.371119C8.90903 0.37035 8.99682 0.387218 9.07875 0.420738C9.16068 0.454259 9.23511 0.503761 9.29771 0.566356C9.3603 0.628951 9.40981 0.703386 9.44333 0.785317C9.47685 0.867247 9.49371 0.955034 9.49294 1.04355C9.49218 1.13207 9.47378 1.21955 9.43885 1.30089C9.40391 1.38222 9.35312 1.45579 9.28944 1.51729L5.51811 5.28862C5.39309 5.4136 5.22355 5.48381 5.04678 5.48381C4.87 5.48381 4.70046 5.4136 4.57544 5.28862L0.804111 1.51729C0.740438 1.45579 0.68965 1.38222 0.65471 1.30089C0.619771 1.21955 0.60138 1.13207 0.600611 1.04355C0.599842 0.955034 0.61671 0.867247 0.65023 0.785317C0.683751 0.703386 0.733253 0.628951 0.795848 0.566356C0.858443 0.503761 0.932878 0.454259 1.01481 0.420738C1.09674 0.387218 1.18453 0.37035 1.27305 0.371119C1.36157 0.371888 1.44905 0.390279 1.53038 0.425218C1.61172 0.460158 1.68528 0.510946 1.74678 0.574619L5.04678 3.87462Z" fill="#A1A6B0"/>
    </svg>
</template>

<script>
export default {
  name: "iconChevronDown"
};
</script>
