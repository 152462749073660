<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.0927559" fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#3965FA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7778 11H26.2222C26.6518 11 27 11.3482 27 11.7778V14.8889C27 15.3184 26.6518 15.6667 26.2222 15.6667H13.7778C13.3482 15.6667 13 15.3184 13 14.8889V11.7778C13 11.3482 13.3482 11 13.7778 11ZM13.7778 17.2222H26.2222C26.6518 17.2222 27 17.5704 27 18V21.1111C27 21.5406 26.6518 21.8889 26.2222 21.8889H13.7778C13.3482 21.8889 13 21.5406 13 21.1111V18C13 17.5704 13.3482 17.2222 13.7778 17.2222ZM26.2222 23.4443H13.7778C13.3482 23.4443 13 23.7925 13 24.2221V27.3332C13 27.7627 13.3482 28.111 13.7778 28.111H26.2222C26.6518 28.111 27 27.7627 27 27.3332V24.2221C27 23.7925 26.6518 23.4443 26.2222 23.4443ZM17.6664 14.1113H18.4442V12.5557H17.6664V14.1113ZM18.4442 20.3334H17.6664V18.7778H18.4442V20.3334ZM17.6664 26.5556H18.4442V25H17.6664V26.5556ZM14.556 14.1113V12.5557H16.1116V14.1113H14.556ZM14.556 18.7778V20.3334H16.1116V18.7778H14.556ZM14.556 26.5556V25H16.1116V26.5556H14.556Z" fill="#175CFF"/>
    </svg>
</template>

<script>
export default {
  name: "iconRoundDatabase"
};
</script>
