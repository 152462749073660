<template>
    <svg width="40" height="40" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.0927559" fill-rule="evenodd" clip-rule="evenodd" d="M18.5 37C28.7173 37 37 28.7173 37 18.5C37 8.28273 28.7173 0 18.5 0C8.28273 0 0 8.28273 0 18.5C0 28.7173 8.28273 37 18.5 37Z" fill="#175CFF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 18.5C9.25 13.3914 13.3914 9.25 18.5 9.25C20.9533 9.25 23.306 10.2246 25.0407 11.9593C26.7754 13.694 27.75 16.0467 27.75 18.5C27.75 20.9533 26.7754 23.306 25.0407 25.0407C23.306 26.7754 20.9533 27.75 18.5 27.75C16.0467 27.75 13.694 26.7754 11.9593 25.0407C10.2246 23.306 9.25 20.9533 9.25 18.5ZM23.9575 23.4858C23.717 22.7458 23.0232 22.2 22.2 22.2H21.275V19.425C21.275 18.9141 20.8609 18.5 20.35 18.5H14.8V16.65H16.65C17.1609 16.65 17.575 16.2359 17.575 15.725V13.875H19.425C20.4467 13.875 21.275 13.0467 21.275 12.025V11.6458C23.9852 12.7373 25.9 15.392 25.9 18.5C25.9 20.424 25.16 22.1723 23.9575 23.4858ZM17.575 25.8352C13.9212 25.382 11.1 22.274 11.1 18.5C11.1 17.9265 11.174 17.3715 11.2943 16.8442L15.725 21.275V22.2C15.725 23.2217 16.5533 24.05 17.575 24.05V25.8352Z" fill="#175CFF"/>
    </svg>
</template>

<script>
export default {
  name: "iconRoundGlobe"
};
</script>
