<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.125 4H7.625C6.72719 4 6.00812 4.72719 6.00812 5.625L6 18.625C6 19.5228 6.71906 20.25 7.61688 20.25H17.375C18.2728 20.25 19 19.5228 19 18.625V8.875L14.125 4ZM15.75 17H9.25V15.375H15.75V17ZM15.75 13.75H9.25V12.125H15.75V13.75ZM13.3125 9.6875V5.21875L17.7812 9.6875H13.3125Z" fill="#66686D"/>
    </svg>
</template>

<script>
export default {
  name: "iconBilling"
};
</script>
