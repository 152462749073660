import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
    // <v-autocomplete v-autocomplete-rm-arrow-focus ...
    nuxtApp.vueApp.directive('autocomplete-rm-arrow-focus', {
        mounted(el) {
            const icons = el.querySelectorAll('.v-autocomplete__menu-icon');
            icons.forEach(icon => {
                if (icon.getAttribute('tabindex') !== '-1') {
                    icon.setAttribute('tabindex', '-1');
                }
            });
        }
    });

    // close v-dialog on esc (does not work when put onto v-dialog)
    // <v-dialog>
    //     <v-div v-on-escape="{ condition: isVisible, action: actionHide }">
    nuxtApp.vueApp.directive('on-escape', {
        mounted(el, binding) {
            const keydownListener = event => {
                if (event.key === 'Escape' && binding.value.condition) {
                    binding.value.action();
                    event.preventDefault();
                }
            };

            el.__vueEscapeListener__ = keydownListener;
            window.addEventListener('keydown', keydownListener);
        },
        beforeUnmount(el) {
            if (el.__vueEscapeListener__) {
                window.removeEventListener('keydown', el.__vueEscapeListener__);
                delete el.__vueEscapeListener__;
            }
        }
    });

});