import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import Team from './Team'
import User from './User'

export default class TeamUser extends BaseModel {

    static entity = 'teamUsers'

    static fields() {
        return {
            id: this.uid(),
            role: this.string(''),
            teamId: this.number(0),
            team: this.hasOne(Team, 'teamId'),
            userId: this.number(0),
            user: this.hasOne(User, 'userId'),
            projectScope: this.string(''),
            projectIds: this.attr([]),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
        }
    }

    static async apiFetchAll() {
        const query = gql`
            query teamUsers {
                teamUsers {
                    id
                    role
                    teamId
                    team {
                        id
                    }
                    userId
                    user {
                        id
                        name
                        email
                    }
                    projectScope
                    projectIds
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, {})
        if (res && res.teamUsers) {
            useRepo(this).save(res.teamUsers)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createTeamUser($teamUser: CreateTeamUserInput!) {
                createTeamUser(teamUser: $teamUser){
                    id
                    role
                    teamId
                    team{
                        id
                    }
                    userId
                    user{
                        id
                        name
                        email
                    }
                    projectScope
                    projectIds
                    created
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createTeamUser) {

            await useRepo(this).save(res.createTeamUser)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateTeamUser($id: Int!, $teamUser: UpdateTeamUserInput!) {
                updateTeamUser(id: $id, teamUser: $teamUser){
                    id
                    role
                    teamId
                    team{
                        id
                    }
                    userId
                    user{
                        id
                        name
                        email
                    }
                    projectScope
                    projectIds
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateTeamUser) {

            await useRepo(this).save(res.updateTeamUser)
        }
        return res
    }

    static async apiDelete(userId) {
        const query = gql`
            mutation deleteTeamUser($id: Int!) {
                deleteTeamUser(id: $id){
                    id
                    role
                    teamId
                    team{
                        id
                    }
                    userId
                    user{
                        id
                        name
                        email
                    }
                    projectScope
                    projectIds
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: userId })
        if (res && res.deleteTeamUser) {
            await useRepo(this).save(res.deleteTeamUser)
        }
        return res
    }

}
