<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.25016 14.3478C7.91674 14.3481 7.58743 14.2753 7.28604 14.1348C6.98466 13.9943 6.71873 13.7895 6.50749 13.5353C6.29625 13.2811 6.14498 12.9837 6.06462 12.6649C5.98426 12.346 5.97682 12.0135 6.04284 11.6914C6.15797 11.1801 6.44889 10.7238 6.86634 10.3997C7.28378 10.0756 7.80207 9.90368 8.33341 9.91304H13.5003C13.5998 9.91304 13.6952 9.87411 13.7655 9.8048C13.8358 9.73549 13.8753 9.64149 13.8753 9.54348V4.73913C13.8753 4.5431 13.7963 4.3551 13.6556 4.21649C13.515 4.07787 13.3242 4 13.1253 4H3.75002C3.5511 4 3.36033 4.07787 3.21968 4.21649C3.07902 4.3551 3 4.5431 3 4.73913V20.2609C3 20.4569 3.07902 20.6449 3.21968 20.7835C3.36033 20.9221 3.5511 21 3.75002 21H13.1253C13.3242 21 13.515 20.9221 13.6556 20.7835C13.7963 20.6449 13.8753 20.4569 13.8753 20.2609V14.7174C13.8753 14.6194 13.8358 14.5254 13.7655 14.4561C13.6952 14.3868 13.5998 14.3478 13.5003 14.3478H8.25016Z" fill="#66686D"/>
        <path d="M20.7192 11.5518L19.8499 10.8674L18.8426 10.0728L16.9675 8.59456C16.8572 8.50792 16.7242 8.45372 16.5839 8.43821C16.4437 8.42269 16.3019 8.44649 16.1747 8.50686C16.0476 8.56722 15.9403 8.66172 15.8652 8.77948C15.7901 8.89724 15.7503 9.03348 15.7503 9.17256V11.0204H8.25003C7.95166 11.0204 7.6655 11.1372 7.45451 11.3451C7.24353 11.553 7.125 11.835 7.125 12.1291C7.125 12.4231 7.24353 12.7051 7.45451 12.913C7.6655 13.121 7.95166 13.2378 8.25003 13.2378H15.7503V15.0856C15.7502 15.2249 15.7901 15.3613 15.8653 15.4792C15.9405 15.5972 16.048 15.6917 16.1754 15.7521C16.3028 15.8124 16.4448 15.836 16.5853 15.8203C16.7257 15.8045 16.8587 15.7499 16.969 15.6629L18.8441 14.1846L19.8514 13.3908L20.7207 12.7056C20.8085 12.6364 20.8795 12.5485 20.9282 12.4486C20.977 12.3487 21.0023 12.2392 21.0023 12.1283C21.0023 12.0175 20.977 11.908 20.9282 11.8081C20.8795 11.7082 20.8085 11.6203 20.7207 11.5511L20.7192 11.5518Z" fill="#66686D"/>
    </svg>
</template>

<script>
export default {
  name: "iconLogout"
};
</script>
