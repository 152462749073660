import { useRepo } from 'pinia-orm';
import { defineStore, getActivePinia } from 'pinia';
import { initializeTaskUpdates, closeWebsocketConnections } from '@/plugins/nodeup-websocket-updates';
import { activateIntercom } from '@/plugins/nodeup-intercom';
import Me from '@/data/models/Me'
import Project from '@/data/models/Project'
import Team from '@/data/models/Team'

export const useStore = defineStore('settings', {
    state: () => ({
        user: {
            id: null,
            name: '',
            email: '',
            defaultTeam: {},
            defaultProject: {}
        },
        team: null,
        project: null,
        toastMessages: [],
        sidebarErrors: [], // sidebar top errors
        sidebarInlineErrors: {}, // sidebar errors shown under specific field, key = field name, value = error
        sidebarInlineErrorsFull: [],
        globalErrors: [], // global top errors
        timeout: null,
        isLoggedIn: false, // when authToken is set into localStorage
        hasNewVersion: false, // frontend has changed and should be reloaded
        seenTipIds: []
    }),

    actions: {
        set(params) {
            Object.keys(params).forEach(key => {
                //console.log("Setting "+key, params[key])
                this[key] = params[key]
            })
        },

        setUserData(user) {
            this.user = user
            localStorage.setItem('userData', JSON.stringify(user))
        },

        restoreUserData() {
            const user = localStorage.getItem('userData')
            if (user) {
                this.user = JSON.parse(user)
            }
        },

        setToastMessage(params) {
            this.toastMessages.push(params);
            setTimeout(() => {
                this.fadeToastMessage(params);
            }, params.duration || 5000);
        },

        fadeToastMessage(params) {
            const index = this.toastMessages.indexOf(params);
            if (index > -1) {
                if (this.toastMessages.length === 1) {
                    this.toastMessages[index].customClass = 'fade-out';
                    // OLD
                    // state.toastMessages.reverse().reverse() // force redraw https://vuejs.org/v2/guide/list.html#Array-Change-Detection

                    // In Pinia, consider triggering reactivity by replacing the array or using a different approach
                    setTimeout(() => {
                        this.clearToastMessage(params);
                    }, 1000);
                } else {
                    this.toastMessages.splice(index, 1);
                }
            }
        },

        clearToastMessage(params) {
            const index = this.toastMessages.indexOf(params);
            if (index > -1) {
                this.toastMessages.splice(index, 1);
            }
        },

        setSidebarError(error) {
            this.setError({
                errorType: 'sidebar',
                error: { message: error }
            });
        },

        setSidebarInlineError(params) {
            this.setError({
                errorType: 'sidebarInline',
                error: { message: params.message, name: params.name, path: params.path }
            });
        },

        setGlobalError(error) {
            this.setError({
                errorType: 'global',
                error: { message: error }
            });
        },

        setError(params) {
            if (params.errorType === 'global') {
                this.globalErrors.push(params.error);
            } else if (params.errorType === 'sidebar') {
                this.sidebarErrors.push(params.error);
            } else if (params.errorType === 'sidebarInline') {
                // VAR 1 - dict, does not include error path
                this.sidebarInlineErrors[params.error.name] = params.error.message;

                // OLD
                // https://v2.vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
                // not working: state.sidebarInlineErrors[params.error.name] = params.error.message
                // working: Vue.set(state.sidebarInlineErrors, params.error.name, params.error.message)


                // VAR 2 - list of dicts (has full error info, including path)
                this.sidebarInlineErrorsFull.push(params.error);
            }
        },

        clearSidebarErrors() {
            this.sidebarErrors = [];
            this.sidebarInlineErrors = {};
            this.sidebarInlineErrorsFull = [];
        },

        clearSidebarInlineError(name) {
            if (name in this.sidebarInlineErrors) {
                delete this.sidebarInlineErrors[name];
            }
        },

        clearSidebarInlineErrorFull(params) {
            this.sidebarInlineErrorsFull = this.sidebarInlineErrorsFull.filter(
                (error) => !(params.name in error && error['path'].includes(params.withPath))
            );
        },

        clearGlobalErrors() {
            this.globalErrors = [];
        },

        async afterLogin(params) {
            /*
            Init state after login
            - methods are attached to window, as context is not ready yet.
            */
            // login errors are now irrelevant
            this.clearGlobalErrors();

            if (params.authToken) {
                console.log('afterLogin() - setting authToken')
                window.$setAuthToken(params.authToken) // setting graphQL authToken for future queries
                localStorage.setItem('authToken', params.authToken);
                this.isLoggedIn = true;
            }

            if (params.userData) {
                console.log('afterLogin() - setting userData')
                this.setUserData(params.userData);
            } else {
                console.log('afterLogin() - restoring userData')
                this.restoreUserData();
            }

            var teamId = null
            var projectId = null
            if (this.user.id == localStorage.getItem('userId')) {
                teamId = localStorage.getItem('teamId')
                projectId = localStorage.getItem('projectId')
            } else if (this.user.defaultTeam) {
                teamId = this.user.defaultTeam.id
                projectId = !!this.user.defaultProject ? this.user.defaultProject.id : 0
            }
            if (!teamId) {
                // possible only when page version is updated without re-logging in
                // can be removed in future
                setTimeout(function () { this.beforeLogout() }, 0)
                return
            }

            await this.setTeamProject({ teamId: teamId, projectId: projectId, userId: this.user.id })

            // refresh token every 10 min, server side expiry is 15 min
            window.$recallRefreshAuthToken()

            // get closed tips, so we can hide those tips in listings
            var seenTipIds = JSON.parse(localStorage.getItem('seenTipIds')) || []
            this.set({ seenTipIds: seenTipIds })

            // must not await (afterLogin() must finish before it can run)
            Team.apiFetchAll()

            // activate intercom messenger (it was disabled on login screen and page is not going to be refreshed only re-routed)
            setTimeout(activateIntercom, 4000);

            // will run when $store is ready with dispatch
            let ctx = this
            setTimeout(function () { useRepo(Me).save({ id: ctx.user.id, name: ctx.user.name, email: ctx.user.email }) }, 0)

            // start ws update connections
            setTimeout(function () { initializeTaskUpdates() }, 0)
        },

        beforeLogout() {
            console.log('Clearing store data and localstorage')

            closeWebsocketConnections()

            this.$reset(); // Resets store to its initial state

            // reset all other stores aswell
            getActivePinia()._s.forEach(store => store.$reset());

            this.isLoggedIn = false;

            localStorage.removeItem('userData');
            localStorage.removeItem('authToken');

            // remember project-team over sessions
            //localStorage.removeItem('userId')
            //localStorage.removeItem('teamId')
            //localStorage.removeItem('projectId')
            //localStorage.removeItem('seenTipIds')
        },

        async setTeam(teamId) {
            /*
            This method sets active team to teamId (or first team).
                - teamId optional
            */
            if (!teamId) {
                const res = useRepo(Team).query().get()
                if (res.length) {
                    teamId = res[0].id
                }
            }
            if (!teamId) {
                // Assuming you have a method to handle global errors
                this.setGlobalError('No teams found!')
                return
            }
            localStorage.setItem('teamId', teamId)
            this.team = parseInt(teamId)
            return teamId
        },

        async setProject(projectId) {
            /*
            This method sets active project.
                - projectId is required!
                - projectId has to be located under current teamId, 0 = no project selected
            */
            if (!projectId) {
                projectId = 0 // all projects have been deleted? setting pseudo projectId
            } else {
                localStorage.setItem('projectId', projectId)
            }
            this.project = parseInt(projectId)

            // Fetch all child objects of an active project
            Project.apiFetchAllData(projectId)
            return projectId
        },

        async setTeamProject({ teamId, projectId, userId }) {
            /*
            This method sets active team and project. Selects first team's first project with no input parameters.
                - teamId optional
                - projectId optional
                - userId optional
            */
            teamId = await this.setTeam(teamId)

            // If no projectId, select the first project under the team
            if (!projectId) {
                const res = Project.getItems({ where: ['teamId', teamId] })
                if (res.length) {
                    projectId = res[0].id
                }
            }

            // Only setting user after initial login
            if (userId) {
                localStorage.setItem('userId', userId)
            }

            return this.setProject(projectId)
        },

        async setSeenTipIds(tipId) {
            /*
            This method marks user closed listing tips as read.
            */
            if (tipId && !this.seenTipIds.includes(tipId)) {
                const seenTipIds = [...this.seenTipIds, tipId]
                localStorage.setItem('seenTipIds', JSON.stringify(seenTipIds))
                this.seenTipIds = seenTipIds
            }
            return this.seenTipIds
        }
    }
});
