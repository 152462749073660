import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import VirtualServerDatabase from './VirtualServerDatabase'

export default class VirtualServerDatabaseUser extends BaseModel {

    static entity = 'virtualServerDatabaseUsers'

    static fields() {
        return {
            id: this.uid(),
            username: this.string(''),
            externalIps: this.attr([]),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            databaseId: this.number(0),
            database: this.belongsTo(VirtualServerDatabase, 'databaseId')
        }
    }

    static getItemsByVirtualServerId(params) {
        const databases = VirtualServerDatabase.getItems({
            where: ['virtualServerId', parseInt(params.virtualServerId)],
            orderBy: 'name'
        });
        var databaseIds = databases.map(o => o.id)
        return this.getItems({
            with: 'database',
            where: ['databaseId', (value) => { return databaseIds.includes(value) }],
            // is not reactive, go between different vs databases, first is ok, second shows previous aswell
            // where: [(item) => { return item.database.virtualServerId == parseInt(params.virtualServerId) }],
            orderBy: 'username'
        })
    }

    static async apiFetch(filter, bypassCache) {
        const query = gql`
            query getVirtualServerDatabaseUsers {
                virtualServerDatabaseUsers {
                    id
                    username
                    externalIps
                    created
                    updated
                    deleted
                    database{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, {})
        if (res && res.createVirtualServerDatabaseUser) {
            useRepo(this).save(res.createVirtualServerDatabaseUser)
        }
        return res
    }

    static async apiInsertMultiple(params) {
        // params = {databaseUsers: [databaseUser:{..}, databaseUser:{..}...]}
        var fragmentsArgs = []
        var fragments = []
        var variables = {}
        params.databaseUsers.forEach(function (databaseUser, i) {
            fragmentsArgs.push(`$databaseUser${i}: CreateDatabaseUserInput!`)
            fragments.push(gql`
                databaseUser${i}: createVirtualServerDatabaseUser(databaseUser: $databaseUser${i}){
                    id
                    username
                    externalIps
                    created
                    updated
                    deleted
                    database{
                        id
                    }
                }
            `)
            variables['databaseUser' + i] = databaseUser
        })
        var query = gql`
            mutation createVirtualServerDatabaseUser(${fragmentsArgs.join(', ')}) {
                ${fragments.join('')}
            }
        `
        const res = await window.$graphQLQuery(query, variables)
        var store = this
        Object.keys(variables).forEach(async function (key) {
            if (res && res[key]) {
                await useRepo(store).save(res[key])
            }
        })
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createVirtualServerDatabaseUser($databaseUser: CreateDatabaseUserInput!) {
                createVirtualServerDatabaseUser(databaseUser: $databaseUser){
                    id
                    username
                    externalIps
                    created
                    updated
                    deleted
                    database {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createVirtualServerDatabaseUser) {
            await useRepo(this).save(res.createVirtualServerDatabaseUser)
        }
        return res
    }

    // static async apiUpdateMultiple (params) {
    //     // params = {databaseUsers: [databaseUser:{..}, databaseUser:{..}...]}
    //     var fragmentsArgs = []
    //     var fragments = []
    //     var variables = {}
    //     params.databaseUsers.forEach(function(databaseUser, i){
    //         fragmentsArgs.push(`$databaseUser${i}: UpdateDatabaseUserInput!`)
    //         fragments.push(gql`
    //             databaseUser${i}: updateVirtualServerDatabaseUser(databaseUser: $databaseUser${i}){
    //                 id
    //                 username
    //                 externalIps
    //                 created
    //                 updated
    //                 deleted
    //                 database{
    //                     id
    //                 }
    //             }
    //         `)
    //         variables['databaseUser'+i] = databaseUser
    //     })
    //     var query = gql`
    //         mutation updateVirtualServerDatabaseUser(${fragmentsArgs.join(', ')}) {
    //             ${fragments.join('')}
    //         }
    //     `
    //     const res = await window.$graphQLQuery(query, variables)
    //     var store = this
    //     Object.keys(variables).forEach(async function(key){
    //         if (res && res[key]){
    //             await store.insertOrUpdate({
    //                 data: res[key]
    //             })
    //         }
    //     })
    //     return res
    // }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateVirtualServerDatabaseUser($databaseUser: UpdateDatabaseUserInput!, $id: Int!) {
                updateVirtualServerDatabaseUser(databaseUser: $databaseUser, id: $id){
                    id
                    username
                    externalIps
                    created
                    updated
                    deleted
                    database {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateVirtualServerDatabaseUser) {
            await useRepo(this).save(res.updateVirtualServerDatabaseUser)
        }
        return res
    }

    static async apiDelete(databaseUserId) {
        const query = gql`
            mutation deleteVirtualServerDatabaseUser($id: Int!) {
                deleteVirtualServerDatabaseUser(id: $id){
                    id
                    username
                    externalIps
                    created
                    updated
                    deleted
                    database {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(databaseUserId) })
        if (res && res.deleteVirtualServerDatabaseUser) {
            await useRepo(this).save(res.deleteVirtualServerDatabaseUser)
        }
        return res
    }
}
