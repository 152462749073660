import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import DomainDnsRecord from './DomainDnsRecord'
import DomainContact from './DomainContact'
import Project from './Project'

export default class Domain extends BaseModel {

    static entity = 'domains'
    static eagerLoad = ['dnsRecords', 'contacts'];

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            nameIdna: this.string(''),
            registrar: this.string(''),
            autoRenew: this.boolean(true),
            renewalAllowed: this.boolean(false),
            renewalNetPrice: this.string(''),
            renewalTotalPrice: this.string(''),
            renewalMinYears: this.number(0),
            renewalMaxYears: this.number(0),
            registryCreated: this.string(null),
            registryUpdated: this.string(null),
            registryExpires: this.string(null),
            status: this.string(''),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            dnsRecords: this.hasMany(DomainDnsRecord, 'domainId'),
            contacts: this.hasMany(DomainContact, 'domainId'),
            projectId: this.number(0),
            project: this.belongsTo(Project, 'projectId'),
            virtualServerId: this.number(0)
        }
    }

    static async apiFetchSingle(domainId) {
        const query = gql`
            query getDomain($id: Int) {
                domain (id: $id){
                    id
                    name
                    nameIdna
                    registrar
                    autoRenew
                    renewalAllowed
                    renewalNetPrice
                    renewalTotalPrice
                    renewalMinYears
                    renewalMaxYears
                    registryCreated
                    registryUpdated
                    registryExpires
                    status
                    created
                    updated
                    deleted
                    contacts{
                        id
                        role
                        identType
                        identCode
                        name
                        email
                        phone
                    }
                    dnsRecords {
                        id
                        data
                        name
                        ttl
                        type
                    }
                    project {
                        id
                    }
                    virtualServerId
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(domainId) })
        if (res && res.domain) {
            useRepo(this).save(res.domain)
        }
        return res
    }

    static async apiFetchAll(projectId) {
        if (!projectId) {
            return []
        }
        const query = gql`
            query getDomains($projectId: Int!) {
                domains (projectId: $projectId){
                    id
                    name
                    nameIdna
                    registrar
                    autoRenew
                    renewalAllowed
                    renewalNetPrice
                    renewalTotalPrice
                    renewalMinYears
                    renewalMaxYears
                    registryCreated
                    registryUpdated
                    registryExpires
                    status
                    created
                    updated
                    deleted
                    project {
                        id
                    }
                    virtualServerId
                }
            }
        `
        const res = await window.$graphQLQuery(query, { projectId: parseInt(projectId) })
        if (res && res.domains) {
            useRepo(this).save(res.domains)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createDomain($domain: CreateDomainInput!) {
                createDomain(domain: $domain){
                    id
                    name
                    nameIdna
                    registrar
                    autoRenew
                    renewalAllowed
                    renewalNetPrice
                    renewalTotalPrice
                    renewalMinYears
                    renewalMaxYears
                    registryCreated
                    registryUpdated
                    registryExpires
                    status
                    created
                    updated
                    deleted
                    contacts{
                        id
                        role
                        identType
                        identCode
                        name
                        email
                        phone
                    }
                    dnsRecords {
                        id
                        data
                        name
                        ttl
                        type
                    }
                    project {
                        id
                    }
                    virtualServerId
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createDomain) {

            await useRepo(this).save(res.createDomain)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateDomain($id: Int!, $domain: UpdateDomainInput!) {
                updateDomain(id: $id, domain: $domain){
                    id
                    name
                    nameIdna
                    registrar
                    autoRenew
                    renewalAllowed
                    renewalNetPrice
                    renewalTotalPrice
                    renewalMinYears
                    renewalMaxYears
                    registryCreated
                    registryUpdated
                    registryExpires
                    status
                    created
                    updated
                    deleted
                    contacts{
                        id
                        role
                        identType
                        identCode
                        name
                        email
                        phone
                    }
                    dnsRecords {
                        id
                        data
                        name
                        ttl
                        type
                    }
                    project {
                        id
                    }
                    virtualServerId
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateDomain) {
            await useRepo(this).save(res.updateDomain)
        }
        return res
    }

    static async apiRenew(params) {
        const query = gql`
            mutation renewDomain($id: Int!, $domain: RenewDomainInput!) {
                renewDomain(id: $id, domain: $domain){
                    id
                    name
                    nameIdna
                    registrar
                    autoRenew
                    renewalAllowed
                    renewalNetPrice
                    renewalTotalPrice
                    renewalMinYears
                    renewalMaxYears
                    registryCreated
                    registryUpdated
                    registryExpires
                    status
                    created
                    updated
                    deleted
                    contacts{
                        id
                        role
                        identType
                        identCode
                        name
                        email
                        phone
                    }
                    dnsRecords {
                        id
                        data
                        name
                        ttl
                        type
                    }
                    project {
                        id
                        team{
                            id
                            estimatedCost
                            estimatedCostStarted
                        }
                    }
                    virtualServerId
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.renewDomain) {
            await useRepo(this).save(res.renewDomain)
        }
        return res
    }

    static async apiDelete(domainId) {
        const query = gql`
            mutation deleteDomain($id: Int!) {
                deleteDomain(id: $id){
                    id
                    name
                    nameIdna
                    registrar
                    autoRenew
                    renewalAllowed
                    renewalNetPrice
                    renewalTotalPrice
                    renewalMinYears
                    renewalMaxYears
                    registryCreated
                    registryUpdated
                    registryExpires
                    status
                    created
                    updated
                    deleted
                    contacts{
                        id
                        role
                        identType
                        identCode
                        name
                        email
                        phone
                    }
                    dnsRecords {
                        id
                        data
                        name
                        ttl
                        type
                    }
                    project {
                        id
                    }
                    virtualServerId
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(domainId) })
        if (res && res.deleteDomain) {
            await useRepo(this).save(res.deleteDomain)
        }
        return res
    }

}
