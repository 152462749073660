import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin(nuxtApp => {
    if (nuxtApp.$config.public.disableConsoleLog === 'true') {
        // Backup original console methods
        window.originalConsole = {
            error: console.error,
            group: console.group,
            log: console.log,
            warn: console.warn
        };

        // Override console methods to prevent logging
        console.error = function () { };
        console.group = function () { };
        console.log = function () { };
        console.warn = function () { };
    }
});
