<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3289 11.8182C19.2518 11.8182 20 11.07 20 10.1471V9.85288C20 8.92998 19.2518 8.18182 18.3289 8.18182H18.0503C17.4523 8.18182 16.9398 7.76598 16.7107 7.21365C16.4816 6.66131 16.5554 6.01547 16.9783 5.59264L17.1751 5.39584C17.8275 4.74343 17.8275 3.68566 17.1751 3.03325L16.9668 2.82494C16.3143 2.17252 15.2566 2.17252 14.6042 2.82494L14.4074 3.02173C13.9845 3.44457 13.3387 3.51845 12.7863 3.28931C12.234 3.06018 11.8182 2.54767 11.8182 1.9497V1.67106C11.8182 0.748158 11.07 0 10.1471 0H9.85288C8.92998 0 8.18182 0.748158 8.18182 1.67106V1.9497C8.18182 2.54767 7.76598 3.06018 7.21365 3.28931C6.66131 3.51845 6.01547 3.44457 5.59264 3.02173L5.39584 2.82494C4.74343 2.17252 3.68566 2.17252 3.03325 2.82494L2.82493 3.03325C2.17252 3.68566 2.17252 4.74343 2.82494 5.39584L3.02173 5.59264C3.44457 6.01547 3.51845 6.66131 3.28931 7.21365C3.06018 7.76598 2.54767 8.18182 1.9497 8.18182H1.67106C0.748158 8.18182 0 8.92998 0 9.85287V10.1471C0 11.07 0.748158 11.8182 1.67106 11.8182H1.9497C2.54767 11.8182 3.06018 12.234 3.28931 12.7863C3.51845 13.3387 3.44457 13.9845 3.02173 14.4074L2.82494 14.6042C2.17252 15.2566 2.17252 16.3143 2.82494 16.9668L3.03361 17.1754C3.68582 17.8276 4.74327 17.8276 5.39548 17.1754L5.59237 16.9785C6.0153 16.5556 6.66114 16.4817 7.21365 16.7108C7.76582 16.9398 8.18182 17.4522 8.18182 18.05V18.3289C8.18182 19.2518 8.92998 20 9.85287 20H10.1471C11.07 20 11.8182 19.2518 11.8182 18.3289V18.0503C11.8182 17.4523 12.234 16.9398 12.7863 16.7107C13.3387 16.4816 13.9845 16.5554 14.4074 16.9783L14.6042 17.1751C15.2566 17.8275 16.3143 17.8275 16.9668 17.1751L17.1754 16.9664C17.8276 16.3142 17.8276 15.2567 17.1754 14.6045L16.9785 14.4076C16.5556 13.9847 16.4817 13.3389 16.7108 12.7864C16.9398 12.2342 17.4522 11.8182 18.05 11.8182H18.3289ZM10 12.7273C8.49364 12.7273 7.27273 11.5064 7.27273 10C7.27273 8.49364 8.49364 7.27273 10 7.27273C11.5064 7.27273 12.7273 8.49364 12.7273 10C12.7273 11.5064 11.5064 12.7273 10 12.7273Z" fill="#66686D"/>
    </svg>
</template>

<script>
export default {
  name: "iconSettings"
};
</script>
