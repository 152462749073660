import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(nuxtApp => {
    /*
    Global classificators

    <script setup>
    import { useNuxtApp } from '#app'
    const nuxtApp = useNuxtApp()
    const phoneCountries = nuxtApp.$phoneCountries()
    const countries = nuxtApp.$countries()
    ...

    */
    console.log('Init plugins/nodeup-classificators.js')

    const phoneCountries = function () {
        return [
            // https://gist.github.com/incredimike/1469814
            // Country names object using 2-letter country codes to reference country name
            // ISO 3166 Alpha-2 Format: [2 letter Country Code]: [Country Name]
            // Sorted alphabetical by country name (special characters on bottom)
            { code: "AF", phonePrefix: "+93", country: "Afghanistan" },
            { code: "AL", phonePrefix: "+355", country: "Albania" },
            { code: "DZ", phonePrefix: "+213", country: "Algeria" },
            { code: "AS", phonePrefix: "+1684", country: "American Samoa" },
            { code: "AD", phonePrefix: "+376", country: "Andorra" },
            { code: "AO", phonePrefix: "+244", country: "Angola" },
            { code: "AI", phonePrefix: "+1264", country: "Anguilla" },
            { code: "AQ", phonePrefix: "", country: "Antarctica" },
            { code: "AG", phonePrefix: "+1268", country: "Antigua and Barbuda" },
            { code: "AR", phonePrefix: "+54", country: "Argentina" },
            { code: "AM", phonePrefix: "+374", country: "Armenia" },
            { code: "AW", phonePrefix: "+297", country: "Aruba" },
            { code: "AU", phonePrefix: "+61", country: "Australia" },
            { code: "AT", phonePrefix: "+43", country: "Austria" },
            { code: "AZ", phonePrefix: "+994", country: "Azerbaijan" },
            { code: "BS", phonePrefix: "+1242", country: "Bahamas (the)" },
            { code: "BH", phonePrefix: "+973", country: "Bahrain" },
            { code: "BD", phonePrefix: "+880", country: "Bangladesh" },
            { code: "BB", phonePrefix: "+1246", country: "Barbados" },
            { code: "BY", phonePrefix: "+375", country: "Belarus" },
            { code: "BE", phonePrefix: "+32", country: "Belgium" },
            { code: "BZ", phonePrefix: "+501", country: "Belize" },
            { code: "BJ", phonePrefix: "+229", country: "Benin" },
            { code: "BM", phonePrefix: "+1441", country: "Bermuda" },
            { code: "BT", phonePrefix: "+975", country: "Bhutan" },
            { code: "BO", phonePrefix: "+591", country: "Bolivia (Plurinational State of)" },
            { code: "BQ", phonePrefix: "+5997", country: "Bonaire, Sint Eustatius and Saba" },
            { code: "BA", phonePrefix: "+387", country: "Bosnia and Herzegovina" },
            { code: "BW", phonePrefix: "+267", country: "Botswana" },
            { code: "BV", phonePrefix: "", country: "Bouvet Island" },
            { code: "BR", phonePrefix: "+55", country: "Brazil" },
            { code: "IO", phonePrefix: "+246", country: "British Indian Ocean Territory (the)" },
            { code: "BN", phonePrefix: "+673", country: "Brunei Darussalam" },
            { code: "BG", phonePrefix: "+359", country: "Bulgaria" },
            { code: "BF", phonePrefix: "+226", country: "Burkina Faso" },
            { code: "BI", phonePrefix: "+257", country: "Burundi" },
            { code: "CV", phonePrefix: "+238", country: "Cabo Verde" },
            { code: "KH", phonePrefix: "+855", country: "Cambodia" },
            { code: "CM", phonePrefix: "+237", country: "Cameroon" },

            // added space to make distinct from USA
            { code: "CA", phonePrefix: "+1 ", country: "Canada" },

            { code: "KY", phonePrefix: "+1345", country: "Cayman Islands (the)" },
            { code: "CF", phonePrefix: "+236", country: "Central African Republic (the)" },
            { code: "TD", phonePrefix: "+235", country: "Chad" },
            { code: "CL", phonePrefix: "+56", country: "Chile" },
            { code: "CN", phonePrefix: "+86", country: "China" },
            { code: "CX", phonePrefix: "+6189164", country: "Christmas Island" },
            { code: "CC", phonePrefix: "+6189162", country: "Cocos (Keeling) Islands (the)" },
            { code: "CO", phonePrefix: "+57", country: "Colombia" },
            { code: "KM", phonePrefix: "+269", country: "Comoros (the)" },
            { code: "CD", phonePrefix: "+243", country: "Congo (the Democratic Republic of the)" },
            { code: "CG", phonePrefix: "+242", country: "Congo (the)" },
            { code: "CK", phonePrefix: "+682", country: "Cook Islands (the)" },
            { code: "CR", phonePrefix: "+506", country: "Costa Rica" },
            { code: "HR", phonePrefix: "+385", country: "Croatia" },
            { code: "CU", phonePrefix: "+53", country: "Cuba" },
            { code: "CW", phonePrefix: "+5999", country: "Curaçao" },
            { code: "CY", phonePrefix: "+357", country: "Cyprus" },
            { code: "CZ", phonePrefix: "+420", country: "Czechia" },
            { code: "CI", phonePrefix: "+225", country: "Côte d'Ivoire" },
            { code: "DK", phonePrefix: "+45", country: "Denmark" },
            { code: "DJ", phonePrefix: "+253", country: "Djibouti" },
            { code: "DM", phonePrefix: "+1767", country: "Dominica" },

            { code: "DO", phonePrefix: "+1809", country: "Dominican Republic (the)" },
            { code: "DO", phonePrefix: "+1829", country: "Dominican Republic (the)" },
            { code: "DO", phonePrefix: "+1849", country: "Dominican Republic (the)" },

            { code: "EC", phonePrefix: "+593", country: "Ecuador" },
            { code: "EG", phonePrefix: "+20", country: "Egypt" },
            { code: "SV", phonePrefix: "+503", country: "El Salvador" },
            { code: "GQ", phonePrefix: "+240", country: "Equatorial Guinea" },
            { code: "ER", phonePrefix: "+291", country: "Eritrea" },
            { code: "EE", phonePrefix: "+372", country: "Estonia" },
            { code: "SZ", phonePrefix: "+268", country: "Eswatini" },
            { code: "ET", phonePrefix: "+251", country: "Ethiopia" },
            { code: "FK", phonePrefix: "+500", country: "Falkland Islands (the) [Malvinas]" },
            { code: "FO", phonePrefix: "+298", country: "Faroe Islands (the)" },
            { code: "FJ", phonePrefix: "+679", country: "Fiji" },
            { code: "FI", phonePrefix: "+358", country: "Finland" },
            { code: "FR", phonePrefix: "+33", country: "France" },
            { code: "GF", phonePrefix: "+594", country: "French Guiana" },
            { code: "PF", phonePrefix: "+689", country: "French Polynesia" },
            { code: "TF", phonePrefix: "", country: "French Southern Territories (the)" },
            { code: "GA", phonePrefix: "+241", country: "Gabon" },
            { code: "GM", phonePrefix: "+220", country: "Gambia (the)" },
            { code: "GE", phonePrefix: "+995", country: "Georgia" },
            { code: "DE", phonePrefix: "+49", country: "Germany" },
            { code: "GH", phonePrefix: "+233", country: "Ghana" },
            { code: "GI", phonePrefix: "+350", country: "Gibraltar" },
            { code: "GR", phonePrefix: "+30", country: "Greece" },
            { code: "GL", phonePrefix: "+299", country: "Greenland" },
            { code: "GD", phonePrefix: "+1473", country: "Grenada" },
            { code: "GP", phonePrefix: "+590", country: "Guadeloupe" },
            { code: "GU", phonePrefix: "+1671", country: "Guam" },
            { code: "GT", phonePrefix: "+502", country: "Guatemala" },
            { code: "GG", phonePrefix: "+44", country: "Guernsey" },
            { code: "GN", phonePrefix: "+224", country: "Guinea" },
            { code: "GW", phonePrefix: "+245", country: "Guinea-Bissau" },
            { code: "GY", phonePrefix: "+592", country: "Guyana" },
            { code: "HT", phonePrefix: "+509", country: "Haiti" },
            { code: "HM", phonePrefix: "", country: "Heard Island and McDonald Islands" },
            { code: "VA", phonePrefix: "+379", country: "Holy See (the)" },
            { code: "HN", phonePrefix: "+504", country: "Honduras" },
            { code: "HK", phonePrefix: "+852", country: "Hong Kong" },
            { code: "HU", phonePrefix: "+36", country: "Hungary" },
            { code: "IS", phonePrefix: "+354", country: "Iceland" },
            { code: "IN", phonePrefix: "+91", country: "India" },
            { code: "ID", phonePrefix: "+62", country: "Indonesia" },
            { code: "IR", phonePrefix: "+98", country: "Iran (Islamic Republic of)" },
            { code: "IQ", phonePrefix: "+964", country: "Iraq" },
            { code: "IE", phonePrefix: "+353", country: "Ireland" },
            { code: "IM", phonePrefix: "+44", country: "Isle of Man" },
            { code: "IL", phonePrefix: "+972", country: "Israel" },
            { code: "IT", phonePrefix: "+39", country: "Italy" },

            { code: "JM", phonePrefix: "+1658", country: "Jamaica" },
            { code: "JM", phonePrefix: "+1876", country: "Jamaica" },

            { code: "JP", phonePrefix: "+81", country: "Japan" },
            { code: "JE", phonePrefix: "+441534", country: "Jersey" },
            { code: "JO", phonePrefix: "+962", country: "Jordan" },
            { code: "KZ", phonePrefix: "+997", country: "Kazakhstan" },
            { code: "KE", phonePrefix: "+254", country: "Kenya" },
            { code: "KI", phonePrefix: "+686", country: "Kiribati" },
            { code: "KP", phonePrefix: "+850", country: "Korea (the Democratic People's Republic of)" },
            { code: "KR", phonePrefix: "+82", country: "Korea (the Republic of)" },
            { code: "KW", phonePrefix: "+965", country: "Kuwait" },
            { code: "KG", phonePrefix: "+996", country: "Kyrgyzstan" },
            { code: "LA", phonePrefix: "+856", country: "Lao People's Democratic Republic (the)" },
            { code: "LV", phonePrefix: "+371", country: "Latvia" },
            { code: "LB", phonePrefix: "+961", country: "Lebanon" },
            { code: "LS", phonePrefix: "+266", country: "Lesotho" },
            { code: "LR", phonePrefix: "+231", country: "Liberia" },
            { code: "LY", phonePrefix: "+218", country: "Libya" },
            { code: "LI", phonePrefix: "+423", country: "Liechtenstein" },
            { code: "LT", phonePrefix: "+370", country: "Lithuania" },
            { code: "LU", phonePrefix: "+352", country: "Luxembourg" },
            { code: "MO", phonePrefix: "+853", country: "Macao" },
            { code: "MG", phonePrefix: "+261", country: "Madagascar" },
            { code: "MW", phonePrefix: "+265", country: "Malawi" },
            { code: "MY", phonePrefix: "+60", country: "Malaysia" },
            { code: "MV", phonePrefix: "+960", country: "Maldives" },
            { code: "ML", phonePrefix: "+223", country: "Mali" },
            { code: "MT", phonePrefix: "+356", country: "Malta" },
            { code: "MH", phonePrefix: "+692", country: "Marshall Islands (the)" },
            { code: "MQ", phonePrefix: "+596", country: "Martinique" },
            { code: "MR", phonePrefix: "+222", country: "Mauritania" },
            { code: "MU", phonePrefix: "+230", country: "Mauritius" },
            { code: "YT", phonePrefix: "+262", country: "Mayotte" },
            { code: "MX", phonePrefix: "+52", country: "Mexico" },
            { code: "FM", phonePrefix: "+691", country: "Micronesia (Federated States of)" },
            { code: "MD", phonePrefix: "+373", country: "Moldova (the Republic of)" },
            { code: "MC", phonePrefix: "+377", country: "Monaco" },
            { code: "MN", phonePrefix: "+976", country: "Mongolia" },
            { code: "ME", phonePrefix: "+382", country: "Montenegro" },
            { code: "MS", phonePrefix: "+1664", country: "Montserrat" },
            { code: "MA", phonePrefix: "+212", country: "Morocco" },
            { code: "MZ", phonePrefix: "+258", country: "Mozambique" },
            { code: "MM", phonePrefix: "+95", country: "Myanmar" },
            { code: "NA", phonePrefix: "+264", country: "Namibia" },
            { code: "NR", phonePrefix: "+674", country: "Nauru" },
            { code: "NP", phonePrefix: "+977", country: "Nepal" },
            { code: "NL", phonePrefix: "+31", country: "Netherlands (the)" },
            { code: "NC", phonePrefix: "+687", country: "New Caledonia" },
            { code: "NZ", phonePrefix: "+64", country: "New Zealand" },
            { code: "NI", phonePrefix: "+505", country: "Nicaragua" },
            { code: "NE", phonePrefix: "+227", country: "Niger (the)" },
            { code: "NG", phonePrefix: "+234", country: "Nigeria" },
            { code: "NU", phonePrefix: "+683", country: "Niue" },
            { code: "NF", phonePrefix: "+6723", country: "Norfolk Island" },
            { code: "MP", phonePrefix: "+1670", country: "Northern Mariana Islands (the)" },
            { code: "NO", phonePrefix: "+47", country: "Norway" },
            { code: "OM", phonePrefix: "+968", country: "Oman" },
            { code: "PK", phonePrefix: "+92", country: "Pakistan" },
            { code: "PW", phonePrefix: "+680", country: "Palau" },
            { code: "PS", phonePrefix: "+970", country: "Palestine, State of" },
            { code: "PA", phonePrefix: "+507", country: "Panama" },
            { code: "PG", phonePrefix: "+675", country: "Papua New Guinea" },
            { code: "PY", phonePrefix: "+595", country: "Paraguay" },
            { code: "PE", phonePrefix: "+51", country: "Peru" },
            { code: "PH", phonePrefix: "+63", country: "Philippines (the)" },
            { code: "PN", phonePrefix: "+64", country: "Pitcairn" },
            { code: "PL", phonePrefix: "+48", country: "Poland" },
            { code: "PT", phonePrefix: "+351", country: "Portugal" },

            { code: "PR", phonePrefix: "+1787", country: "Puerto Rico" },
            { code: "PR", phonePrefix: "+1939", country: "Puerto Rico" },

            { code: "QA", phonePrefix: "+974", country: "Qatar" },
            { code: "MK", phonePrefix: "+389", country: "Republic of North Macedonia" },
            { code: "RO", phonePrefix: "+40", country: "Romania" },
            { code: "RU", phonePrefix: "+7", country: "Russian Federation (the)" },
            { code: "RW", phonePrefix: "+250", country: "Rwanda" },
            { code: "RE", phonePrefix: "+262", country: "Réunion" },
            { code: "BL", phonePrefix: "+590", country: "Saint Barthélemy" },
            { code: "SH", phonePrefix: "+290", country: "Saint Helena, Ascension and Tristan da Cunha" },
            { code: "KN", phonePrefix: "+1869", country: "Saint Kitts and Nevis" },
            { code: "LC", phonePrefix: "+1758", country: "Saint Lucia" },
            { code: "MF", phonePrefix: "+590", country: "Saint Martin (French part)" },
            { code: "PM", phonePrefix: "+508", country: "Saint Pierre and Miquelon" },
            { code: "VC", phonePrefix: "+1784", country: "Saint Vincent and the Grenadines" },
            { code: "WS", phonePrefix: "+685", country: "Samoa" },
            { code: "SM", phonePrefix: "+378", country: "San Marino" },
            { code: "ST", phonePrefix: "+239", country: "Sao Tome and Principe" },
            { code: "SA", phonePrefix: "+966", country: "Saudi Arabia" },
            { code: "SN", phonePrefix: "+221", country: "Senegal" },
            { code: "RS", phonePrefix: "+381", country: "Serbia" },
            { code: "SC", phonePrefix: "+248", country: "Seychelles" },
            { code: "SL", phonePrefix: "+232", country: "Sierra Leone" },
            { code: "SG", phonePrefix: "+65", country: "Singapore" },
            { code: "SX", phonePrefix: "+1721", country: "Sint Maarten (Dutch part)" },
            { code: "SK", phonePrefix: "+421", country: "Slovakia" },
            { code: "SI", phonePrefix: "+386", country: "Slovenia" },
            { code: "SB", phonePrefix: "+677", country: "Solomon Islands" },
            { code: "SO", phonePrefix: "+252", country: "Somalia" },
            { code: "ZA", phonePrefix: "+27", country: "South Africa" },
            { code: "GS", phonePrefix: "+500", country: "South Georgia and the South Sandwich Islands" },
            { code: "SS", phonePrefix: "+211", country: "South Sudan" },
            { code: "ES", phonePrefix: "+34", country: "Spain" },
            { code: "LK", phonePrefix: "+94", country: "Sri Lanka" },
            { code: "SD", phonePrefix: "+249", country: "Sudan (the)" },
            { code: "SR", phonePrefix: "+597", country: "Suriname" },
            { code: "SJ", phonePrefix: "+4779", country: "Svalbard and Jan Mayen" },
            { code: "SE", phonePrefix: "+46", country: "Sweden" },
            { code: "CH", phonePrefix: "+41", country: "Switzerland" },
            { code: "SY", phonePrefix: "+963", country: "Syrian Arab Republic" },
            { code: "TW", phonePrefix: "+886", country: "Taiwan" },
            { code: "TJ", phonePrefix: "+992", country: "Tajikistan" },
            { code: "TZ", phonePrefix: "+255", country: "Tanzania, United Republic of" },
            { code: "TH", phonePrefix: "+66", country: "Thailand" },
            { code: "TL", phonePrefix: "+670", country: "Timor-Leste" },
            { code: "TG", phonePrefix: "+228", country: "Togo" },
            { code: "TK", phonePrefix: "+690", country: "Tokelau" },
            { code: "TO", phonePrefix: "+676", country: "Tonga" },
            { code: "TT", phonePrefix: "+1868", country: "Trinidad and Tobago" },
            { code: "TN", phonePrefix: "+216", country: "Tunisia" },
            { code: "TR", phonePrefix: "+90", country: "Turkey" },
            { code: "TM", phonePrefix: "+993", country: "Turkmenistan" },
            { code: "TC", phonePrefix: "+1649", country: "Turks and Caicos Islands (the)" },
            { code: "TV", phonePrefix: "+688", country: "Tuvalu" },
            { code: "UG", phonePrefix: "+256", country: "Uganda" },
            { code: "UA", phonePrefix: "+380", country: "Ukraine" },
            { code: "AE", phonePrefix: "+971", country: "United Arab Emirates (the)" },
            { code: "GB", phonePrefix: "+44", country: "United Kingdom of Great Britain and Northern Ireland (the)" },
            { code: "UM", phonePrefix: "", country: "United States Minor Outlying Islands (the)" },
            { code: "US", phonePrefix: "+1", country: "United States of America (the)" },
            { code: "UY", phonePrefix: "+598", country: "Uruguay" },
            { code: "UZ", phonePrefix: "+998", country: "Uzbekistan" },
            { code: "VU", phonePrefix: "+678", country: "Vanuatu" },
            { code: "VE", phonePrefix: "+58", country: "Venezuela (Bolivarian Republic of)" },
            { code: "VN", phonePrefix: "+84", country: "Viet Nam" },
            { code: "VG", phonePrefix: "+1284", country: "Virgin Islands (British)" },
            { code: "VI", phonePrefix: "+1340", country: "Virgin Islands (U.S.)" },
            { code: "WF", phonePrefix: "+681", country: "Wallis and Futuna" },
            { code: "EH", phonePrefix: "+212", country: "Western Sahara" },
            { code: "YE", phonePrefix: "+967", country: "Yemen" },
            { code: "ZM", phonePrefix: "+260", country: "Zambia" },
            { code: "ZW", phonePrefix: "+263", country: "Zimbabwe" },
            { code: "AX", phonePrefix: "+35818", country: "Åland Islands" },
        ]
    }

    // nuxtapp.$phoneCountries
    nuxtApp.provide('phoneCountries', () => phoneCountries())

    // Removing duplicate codes generated by different phone prefixes
    const countries = [...new Map(phoneCountries().map(item => [item.code, item])).values()]
    nuxtApp.provide('countries', () => countries)
});
