import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import VirtualServerEmailList from './VirtualServerEmailList'

export default class VirtualServerEmailListMember extends BaseModel {

    static entity = 'virtualServerEmailListMembers'

    static fields() {
        return {
            id: this.uid(),
            address: this.string(''),
            name: this.string(''),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            emailListId: this.number(0),
            emailList: this.belongsTo(VirtualServerEmailList, 'emailListId')
        }
    }

    static async apiFetchAll(emailListId) {
        const query = gql`
            query getVirtualServerEmailListMembers($emailList: CreateEmailListMemberInput!) {
                virtualServerEmailListMembers(emailList: $emailList){
                    id
                    address
                    name
                    created
                    updated
                    deleted
                    emailList{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { emailList: { id: parseInt(emailListId) } })
        if (res && res.virtualServerEmailListMembers) {
            useRepo(this).save(res.virtualServerEmailListMembers)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createVirtualServerEmailListMember($emailListMember: CreateEmailListMemberInput!) {
                createVirtualServerEmailListMember(emailListMember: $emailListMember){
                    id
                    address
                    name
                    created
                    updated
                    deleted
                    emailList{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createVirtualServerEmailListMember) {

            await useRepo(this).save(res.createVirtualServerEmailListMember)
        }
        return res
    }

    // static async apiInsertMultiple (params) {
    //     // params = {emailListMembers: [emailListMember:{..}, emailListMember:{..}...]}
    //     var fragmentsArgs = []
    //     var fragments = []
    //     var variables = {}
    //     params.emailListMembers.forEach(function(emailListMember, i){
    //         fragmentsArgs.push(`$emailListMember${i}: CreateEmailListMemberInput!`)
    //         fragments.push(gql`
    //             emailListMember${i}: createVirtualServerEmailListMember(emailListMember: $emailListMember${i}){
    //                 id
    //                 address
    //                 created
    //                 updated
    //                 deleted
    //                 emailList{
    //                     id
    //                 }
    //             }
    //         `)
    //         variables['emailListMember'+i] = emailListMember
    //     })
    //     var query = gql`
    //         mutation createVirtualServerEmailListMember(${fragmentsArgs.join(', ')}) {
    //             ${fragments.join('')}
    //         }
    //     `
    //     const res = await window.$graphQLQuery(query, variables)
    //     var store = this
    //     Object.keys(variables).forEach(async function(key){
    //         if (res && res[key]){
    //             await store.insertOrUpdate({
    //                 data: res[key]
    //             })
    //         }
    //     })
    //     return res
    // }

    static async apiDelete(emailListMemberIds) {
        const query = gql`
            mutation deleteVirtualServerEmailListMember($emailListMember: DeleteEmailListMemberInput!) {
                deleteVirtualServerEmailListMember(emailListMember: $emailListMember){
                    id
                    address
                    name
                    created
                    updated
                    deleted
                    emailList{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { emailListMember: { ids: emailListMemberIds } })
        if (res && res.deleteVirtualServerEmailListMember) {
            await useRepo(this).save(res.deleteVirtualServerEmailListMember)
        }
        return res
    }
}
