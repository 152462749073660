<template>
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 12.0001C4.958 12.0071 0 9.31408 0 6.00008C0 2.68608 4.984 -0.0169202 10 7.9752e-05C15.016 0.0170798 20 2.68608 20 6.00008C20 9.31408 15.042 11.9931 10 12.0001ZM10 10.0001C11.0609 10.0001 12.0783 9.57865 12.8284 8.82851C13.5786 8.07836 14 7.06095 14 6.00008C14 4.93921 13.5786 3.9218 12.8284 3.17165C12.0783 2.42151 11.0609 2.00008 10 2.00008C8.93913 2.00008 7.92172 2.42151 7.17157 3.17165C6.42143 3.9218 6 4.93921 6 6.00008C6 7.06095 6.42143 8.07836 7.17157 8.82851C7.92172 9.57865 8.93913 10.0001 10 10.0001ZM10 8.00008C9.46957 8.00008 8.96086 7.78937 8.58579 7.41429C8.21071 7.03922 8 6.53051 8 6.00008C8 5.46965 8.21071 4.96094 8.58579 4.58587C8.96086 4.21079 9.46957 4.00008 10 4.00008C10.5304 4.00008 11.0391 4.21079 11.4142 4.58587C11.7893 4.96094 12 5.46965 12 6.00008C12 6.53051 11.7893 7.03922 11.4142 7.41429C11.0391 7.78937 10.5304 8.00008 10 8.00008Z" fill="#66686D"/>
    </svg>
</template>
<script>
export default {
  name: "iconEye"
}
</script>
