<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#175CFF"/>
        <path transform="translate(13, 8)" d="M10.75 12.8457C11.7994 12.0586 12.5746 10.9614 12.9657 9.70929C13.3568 8.4572 13.3441 7.11379 12.9293 5.86929C12.5144 4.62487 11.7186 3.54245 10.6544 2.77545C9.59025 2.00845 8.31175 1.5957 7 1.5957C5.68825 1.5957 4.40975 2.00845 3.34558 2.77545C2.28142 3.54245 1.48558 4.62487 1.07075 5.86929C0.655917 7.11379 0.643168 8.4572 1.03425 9.70929C1.42542 10.9614 2.20058 12.0586 3.25 12.8457" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path transform="translate(14, 17)" d="M3.50014 12.375L1.19681 9.78333C0.968223 9.52608 0.851141 9.1885 0.871391 8.84492C0.891641 8.50133 1.04747 8.17992 1.30472 7.95125C1.56206 7.72258 1.89956 7.6055 2.24314 7.62575C2.58672 7.646 2.90822 7.80192 3.13681 8.05917L4.75014 9.875V2.375C4.75014 2.0435 4.88189 1.7255 5.11631 1.49108C5.35072 1.25667 5.66864 1.125 6.00014 1.125C6.33172 1.125 6.64964 1.25667 6.88406 1.49108C7.11847 1.7255 7.25014 2.0435 7.25014 2.375V8H8.50014C9.49472 8 10.4486 8.39508 11.1518 9.09833C11.8551 9.80158 12.2501 10.7554 12.2501 11.75V12.3758" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  name: "iconRoundTouchFinger"
};
</script>
