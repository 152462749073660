<template>
    <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0071 5.1L11.4603 15.3935C11.4375 15.8275 11.2565 16.2359 10.9548 16.5347C10.653 16.8335 10.2533 17 9.83775 17H3.16225C2.74674 17 2.34702 16.8335 2.04523 16.5347C1.74345 16.2359 1.56254 15.8275 1.53969 15.3935L0.992875 5.1H12.0071ZM0.8125 0H12.1875C12.403 0 12.6097 0.0895533 12.762 0.248959C12.9144 0.408365 13 0.624566 13 0.85V2.55C13 2.77543 12.9144 2.99164 12.762 3.15104C12.6097 3.31045 12.403 3.4 12.1875 3.4H0.8125C0.597012 3.4 0.390349 3.31045 0.237976 3.15104C0.0856024 2.99164 0 2.77543 0 2.55V0.85C0 0.624566 0.0856024 0.408365 0.237976 0.248959C0.390349 0.0895533 0.597012 0 0.8125 0V0ZM4.875 6.8C4.65951 6.8 4.45285 6.88955 4.30048 7.04896C4.1481 7.20837 4.0625 7.42457 4.0625 7.65V13.6C4.0625 13.8254 4.1481 14.0416 4.30048 14.201C4.45285 14.3604 4.65951 14.45 4.875 14.45C5.09049 14.45 5.29715 14.3604 5.44952 14.201C5.6019 14.0416 5.6875 13.8254 5.6875 13.6V7.65C5.6875 7.42457 5.6019 7.20837 5.44952 7.04896C5.29715 6.88955 5.09049 6.8 4.875 6.8ZM8.125 6.8C7.90951 6.8 7.70285 6.88955 7.55048 7.04896C7.3981 7.20837 7.3125 7.42457 7.3125 7.65V13.6C7.3125 13.8254 7.3981 14.0416 7.55048 14.201C7.70285 14.3604 7.90951 14.45 8.125 14.45C8.34049 14.45 8.54715 14.3604 8.69952 14.201C8.8519 14.0416 8.9375 13.8254 8.9375 13.6V7.65C8.9375 7.42457 8.8519 7.20837 8.69952 7.04896C8.54715 6.88955 8.34049 6.8 8.125 6.8Z" fill="#A1A6B0"/>
    </svg>
</template>

<script>
export default {
  name: "iconTrashBin"
};
</script>
