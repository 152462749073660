<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.586 5.65704L6.636 1.70704C6.45384 1.51844 6.35305 1.26584 6.35533 1.00364C6.3576 0.741443 6.46277 0.49063 6.64818 0.305222C6.83359 0.119814 7.0844 0.0146453 7.3466 0.0123669C7.6088 0.0100885 7.8614 0.110883 8.05 0.293041L13.707 5.95004C13.8002 6.04269 13.8741 6.15285 13.9246 6.27419C13.9751 6.39552 14.001 6.52563 14.001 6.65704C14.001 6.78845 13.9751 6.91856 13.9246 7.03989C13.8741 7.16123 13.8002 7.27139 13.707 7.36404L8.05 13.021C7.95775 13.1166 7.84741 13.1927 7.7254 13.2451C7.6034 13.2976 7.47218 13.3251 7.3394 13.3263C7.20662 13.3274 7.07494 13.3021 6.95205 13.2519C6.82915 13.2016 6.7175 13.1273 6.62361 13.0334C6.52971 12.9395 6.45546 12.8279 6.40518 12.705C6.3549 12.5821 6.3296 12.4504 6.33075 12.3176C6.3319 12.1849 6.35949 12.0536 6.4119 11.9316C6.46431 11.8096 6.54049 11.6993 6.636 11.607L10.586 7.65704H1C0.734784 7.65704 0.48043 7.55168 0.292893 7.36415C0.105357 7.17661 0 6.92226 0 6.65704C0 6.39182 0.105357 6.13747 0.292893 5.94993C0.48043 5.7624 0.734784 5.65704 1 5.65704H10.586V5.65704Z" fill="#66686D"/>
    </svg>
</template>

<script>
export default {
  name: "iconArrowRight"
};
</script>
