<template>
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 5.25364L5.39818 9.65182L14.05 1" stroke="#175CFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  name: "iconTickBlue"
};
</script>
