import { defineNuxtPlugin } from '#app';
import { ClientError, GraphQLClient } from 'graphql-request';

export default defineNuxtPlugin(nuxtApp => {
    console.log('Init plugins/graphql-request.js');

    /*
    <script setup>
    import { useNuxtApp } from '#app'
    const nuxtApp = useNuxtApp();
    const graphQLQuery = nuxtApp.$graphQLQuery;
    */
    const graphQLClient = new GraphQLClient(nuxtApp.$config.public.graphqlUrl);
    function timer(ms) { return new Promise(res => setTimeout(res, ms)); }

    const graphQLQuery = async (query, variables, isAuthQuery = false) => {

        // delay regular query while re-authentication is finished
        if (!isAuthQuery) {
            while (window.isAuthenticating) {
                console.log('Delay gql query till login/reauth returns token')
                await timer(50) // 50ms
            }

            // cancel query when no auth header (just logged out)
            if (!('requestConfig' in graphQLClient && 'headers' in graphQLClient.requestConfig && 'authorization' in graphQLClient.requestConfig.headers)) {
                console.log('Cancel following gql query as we do not have auth header set --> ', query)
                return
            }
        }

        try {

            // console.log("graphQL request --------------->\n", query)

            const response = await graphQLClient.rawRequest(query, variables)
            // console.log("gQL response: ", JSON.stringify(response, undefined, 2))

            if (response.status == 200 && !response.errors && response.data) {
                // console.log("graphQL response ---------------> \n", JSON.stringify(response.data, undefined, 2))
                return response.data

            } else {
                console.log("Turning response into error")
                const errorResult = (typeof response === 'string') ? { error: response } : response.errors
                throw new ClientError(
                    { ...errorResult, status: response.status },
                    { query, variables },
                )
            }

        } catch (error) {
            console.log("Catch Error", JSON.stringify(error, undefined, 2)) // example at the end of this file
            if ('response' in error) {
                error = error.response
            }
            if ('errors' in error) {
                error = error.errors
            }
            nuxtApp.vueApp.config.errorHandler(error, null, 'graphQLError')
        }
    }

    const setAuthToken = function (authToken) {
        if (authToken) {
            graphQLClient.setHeader('authorization', 'Bearer ' + authToken)
        }
    }

    const clearAuthToken = function () {
        if ('requestConfig' in graphQLClient && 'headers' in graphQLClient.requestConfig && 'authorization' in graphQLClient.requestConfig.headers) {
            delete graphQLClient.requestConfig.headers.authorization
        }
    }

    nuxtApp.provide('graphQLQuery', graphQLQuery);


    if (process.client) {
        // we want to be able to use $graphQLQuery before window.$graphQLQuery is set, while plugins are being loaded
        window.$graphQLQuery = graphQLQuery;
        window.$graphQLClient = graphQLClient;
        window.$setAuthToken = setAuthToken;
        window.$clearAuthToken = clearAuthToken;
        document.dispatchEvent(new Event('GraphQLReady'));
    }
});
