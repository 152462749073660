<template>
    <v-container class="d-flex flex-column align-center justify-center height-100">
        <div class="logo mb-16">
            <nuxt-link to="/">
                <img src="/static/images/nodeup-logo.svg" class="logo-img">
            </nuxt-link>
        </div>
        <h1 v-if="error?.statusCode === 404">You’ve found a page that doesn’t exist</h1>
        <h1 v-else>An error occurred</h1>
        <v-btn size="large" color="primary-blue" class="mt-16" to="/">Back to home</v-btn>
    </v-container>
</template>

<script setup>
import { definePageMeta, useError } from '#imports'

definePageMeta({
    layout: 'unauthenticated',
});

const error = useError();
</script>

<style lang="sass">
  @import assets/stylesheets/ordered.scss
</style>

<style lang="scss">
body,
#__nuxt,
.height-100 {
    height: 100% !important;
}

.v-container {
    max-width: 100% !important;
    width: 100% !important;
}

.logo-img {
    height: 44px;
}

@media (max-width: 820px) {
    .logo-img {
        height: 28px;
    }
}
</style>
