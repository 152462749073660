<template>
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.12392 5.04711L5.42392 8.34711C5.48759 8.4086 5.53838 8.48217 5.57332 8.5635C5.60826 8.64484 5.62665 8.73232 5.62742 8.82084C5.62819 8.90936 5.61132 8.99714 5.5778 9.07907C5.54428 9.16101 5.49477 9.23544 5.43218 9.29804C5.36958 9.36063 5.29515 9.41013 5.21322 9.44365C5.13129 9.47717 5.0435 9.49404 4.95498 9.49327C4.86646 9.4925 4.77898 9.47411 4.69765 9.43917C4.61631 9.40423 4.54275 9.35345 4.48125 9.28977L0.709916 5.51844C0.584935 5.39342 0.514725 5.22388 0.514725 5.04711C0.514725 4.87033 0.584935 4.70079 0.709916 4.57577L4.48125 0.804439C4.54275 0.740766 4.61631 0.689978 4.69765 0.655038C4.77898 0.620099 4.86646 0.601708 4.95498 0.600939C5.0435 0.60017 5.13129 0.617038 5.21322 0.650558C5.29515 0.684079 5.36958 0.733581 5.43218 0.796176C5.49477 0.858771 5.54428 0.933206 5.5778 1.01514C5.61132 1.09707 5.62819 1.18485 5.62742 1.27337C5.62665 1.36189 5.60826 1.44937 5.57332 1.53071C5.53838 1.61205 5.48759 1.68561 5.42392 1.74711L2.12392 5.04711Z" fill="#B4B9BD"/>
    </svg>
</template>

<script>
export default {
  name: "iconChevronLeft"
};
</script>
