import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import VirtualServerSubdomain from './VirtualServerSubdomain'

export default class VirtualServerBasicAuth extends BaseModel {

    static entity = 'virtualServerBasicAuths'

    static fields() {
        return {
            id: this.uid(),
            title: this.string(''),
            username: this.string(''),
            password: this.string(''),
            path: this.string(''),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            subdomainId: this.attr(null),
            subdomain: this.belongsTo(VirtualServerSubdomain, 'subdomainId'),
        }
    }


    static getItemsByVirtualServerId(params) {
        // is not reactive, go between different vs passwords, first is ok, second shows previous aswell
        // return this.getItems({
        //     with: 'subdomain',
        //     where: [(item) => { return item.subdomain && item.subdomain.virtualServerId === parseInt(params.virtualServerId) }],
        //     orderBy: 'username'
        // })
        const query = useRepo(this).query()
        query.whereHas('subdomain', (query) => {
            query.where('virtualServerId', parseInt(params.virtualServerId))
        })
        query.where((item) => item.deleted == null)
        return query.orderBy('username').get()
    }

    static async apiFetchAll(virtualServerId) {
        const query = gql`
            query getVirtualServerBasicAuths($virtualServerId: Int) {
                virtualServerBasicAuths(virtualServerId: $virtualServerId) {
                    id
                    title
                    username
                    path
                    created
                    updated
                    deleted
                    subdomain{
                        id
                        virtualServer{
                            id
                        }
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { virtualServerId: parseInt(virtualServerId) })
        if (res && res.virtualServerBasicAuths) {
            useRepo(this).save(res.virtualServerBasicAuths)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createVirtualServerBasicAuth($basicAuth: CreateBasicAuthInput!) {
                createVirtualServerBasicAuth(basicAuth: $basicAuth){
                    id
                    title
                    username
                    path
                    created
                    updated
                    deleted
                    subdomain{
                        id
                        virtualServer{
                            id
                        }
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createVirtualServerBasicAuth) {
            await useRepo(this).save(res.createVirtualServerBasicAuth)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateVirtualServerBasicAuth($basicAuth: UpdateBasicAuthInput!, $id: Int!) {
                updateVirtualServerBasicAuth(basicAuth: $basicAuth, id: $id){
                    id
                    title
                    username
                    path
                    created
                    updated
                    deleted
                    subdomain{
                        id
                        virtualServer{
                            id
                        }
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateVirtualServerBasicAuth) {
            await useRepo(this).save(res.updateVirtualServerBasicAuth)
        }
        return res
    }

    static async apiDelete(basicAuthId) {
        const query = gql`
            mutation deleteVirtualServerBasicAuth($id: Int!) {
                deleteVirtualServerBasicAuth(id: $id){
                    id
                    title
                    username
                    path
                    created
                    updated
                    deleted
                    subdomain{
                        id
                        virtualServer{
                            id
                        }
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(basicAuthId) })
        if (res && res.deleteVirtualServerBasicAuth) {
            await useRepo(this).save(res.deleteVirtualServerBasicAuth)
        }
        return res
    }
}
