<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.0927559" fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#3965FA"/>
        <path d="M26.0071 16.1L25.4603 26.3935C25.4375 26.8275 25.2565 27.2359 24.9548 27.5347C24.653 27.8335 24.2533 28 23.8377 28H17.1623C16.7467 28 16.347 27.8335 16.0452 27.5347C15.7435 27.2359 15.5625 26.8275 15.5397 26.3935L14.9929 16.1H26.0071ZM14.8125 11H26.1875C26.403 11 26.6097 11.0896 26.762 11.249C26.9144 11.4084 27 11.6246 27 11.85V13.55C27 13.7754 26.9144 13.9916 26.762 14.151C26.6097 14.3104 26.403 14.4 26.1875 14.4H14.8125C14.597 14.4 14.3903 14.3104 14.238 14.151C14.0856 13.9916 14 13.7754 14 13.55V11.85C14 11.6246 14.0856 11.4084 14.238 11.249C14.3903 11.0896 14.597 11 14.8125 11V11ZM18.875 17.8C18.6595 17.8 18.4528 17.8896 18.3005 18.049C18.1481 18.2084 18.0625 18.4246 18.0625 18.65V24.6C18.0625 24.8254 18.1481 25.0416 18.3005 25.201C18.4528 25.3604 18.6595 25.45 18.875 25.45C19.0905 25.45 19.2972 25.3604 19.4495 25.201C19.6019 25.0416 19.6875 24.8254 19.6875 24.6V18.65C19.6875 18.4246 19.6019 18.2084 19.4495 18.049C19.2972 17.8896 19.0905 17.8 18.875 17.8ZM22.125 17.8C21.9095 17.8 21.7028 17.8896 21.5505 18.049C21.3981 18.2084 21.3125 18.4246 21.3125 18.65V24.6C21.3125 24.8254 21.3981 25.0416 21.5505 25.201C21.7028 25.3604 21.9095 25.45 22.125 25.45C22.3405 25.45 22.5472 25.3604 22.6995 25.201C22.8519 25.0416 22.9375 24.8254 22.9375 24.6V18.65C22.9375 18.4246 22.8519 18.2084 22.6995 18.049C22.5472 17.8896 22.3405 17.8 22.125 17.8Z" fill="#175CFF"/>
    </svg>
</template>

<script>
export default {
  name: "iconRoundTrashbin"
};
</script>
