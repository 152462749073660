import { Model } from 'pinia-orm';
import { useRepo } from 'pinia-orm';

export class BaseModel extends Model {

    static getItems(params = {}) {
        const query = useRepo(this).query()

        if (params.with) {
            if (Array.isArray(params.with)) {
                params.with.forEach(relation => {
                    query.with(relation);
                });
            } else {
                query.with(params.with);
            }
        }

        if (Array.isArray(params.where)) {
            if (params.where.every(item => Array.isArray(item))) {
                params.where.forEach(([field, value]) => {
                    query.where(field, value);
                });
            } else if (params.where.length === 2) {
                const [field, value] = params.where;
                query.where(field, value);
            }
        }
        query.where((item) => (item.deleted === null || item.deleted === undefined));

        if (params.orderBy) {
            query.orderBy(item => item[params.orderBy].toLowerCase(), params?.orderBy || 'ASC')
        }

        return query.get()
    }

}