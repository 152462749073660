import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import Project from './Project'
import Package from './Package'
import Task from './Task'

export default class Vm extends BaseModel {

    static entity = 'vms'

    static fields() {
        return {
            id: this.uid(),
            uuid: this.string(''),
            name: this.string(''),
            powerState: this.string(''),
            cpuCount: this.number(0),
            diskAmount: this.string(''),
            memoryAmount: this.string(''),
            networkCount: this.string(''),
            networkIps: this.attr([]),
            osVersion: this.string(''),
            city: this.string(''),
            datacenter: this.string(''),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            packageId: this.attr(''),
            package: this.belongsTo(Package, 'packageId'),
            projectId: this.number(0),
            project: this.belongsTo(Project, 'projectId'),

            tasks: this.morphMany(Task, 'objectId', 'contentType')
        }
    }

    static async apiFetchSingle(vmId) {
        var query = gql`
            query getVm($id: Int!) {
                vm(id: $id) {
                    id
                    uuid
                    name
                    powerState
                    cpuCount
                    diskAmount
                    memoryAmount
                    networkCount
                    networkIps
                    osVersion
                    city
                    datacenter
                    project {
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(vmId) })
        if (res && res.vm) {
            useRepo(this).save(res.vm)
        }
        return res
    }

    static async apiFetchAll(projectId) {
        if (!projectId) {
            return []
        }
        var query = gql`
            query getVms($projectId: Int) {
                vms(projectId: $projectId) {
                    id
                    uuid
                    name
                    powerState
                    cpuCount
                    diskAmount
                    memoryAmount
                    networkCount
                    networkIps
                    osVersion
                    project {
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { projectId: parseInt(projectId) })
        if (res && res.vms) {
            useRepo(this).save(res.vms)
        }
        return res
    }

    static async apiManage(params) {
        const query = gql`
            mutation manageVm($id: Int!, $vm: ManageVmInput!) {
                manageVm(id: $id, vm: $vm){
                    id
                    uuid
                    name
                    powerState
                    cpuCount
                    diskAmount
                    memoryAmount
                    networkCount
                    networkIps
                    osVersion
                    city
                    datacenter
                    project {
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.manageVm) {
            useRepo(this).save(res.manageVm)
        }
        return res
    }
}
