import iconArrowRight from "~/components/icons/arrow-right";
import iconMagnify from "~/components/icons/magnify";
import iconPencil from "~/components/icons/pencil";
import iconPlus from "~/components/icons/plus";
import iconPlusBlue from "~/components/icons/plus-blue";
import iconTrashBin from "~/components/icons/trashbin";
import iconAlarmBell from "~/components/icons/alarmbell";
import iconChevronLeft from "~/components/icons/chevronleft";
import iconChevronRight from "~/components/icons/chevronright";
import iconChevronDown from "~/components/icons/chevrondown";
import iconProjects from "~/components/icons/projects";
import iconRoundGlobe from "~/components/icons/round-globe";
import iconRoundRenew from "~/components/icons/round-renew";
import iconRoundComputer from "~/components/icons/round-computer";
import iconRoundEmail from "~/components/icons/round-email";
import iconRoundTrashbin from "~/components/icons/round-trashbin";
import iconRoundTouchFinger from "~/components/icons/round-touch-finger";
import iconRoundDatabase from "~/components/icons/round-database";
import iconRoundInvoice from "~/components/icons/round-invoice";
import iconRoundPencil from "~/components/icons/round-pencil";
import iconTickBlue from "~/components/icons/tick-blue";
import iconCopy from "~/components/icons/copy";
import iconEye from "~/components/icons/eye";
import iconEyeOff from "~/components/icons/eye-off";
import iconFolderOn from "~/components/icons/folder-on";
import iconFolderOff from "~/components/icons/folder-off";
import iconCheckMinus from "~/components/icons/check-minus";
import iconCheckOn from "~/components/icons/check-on";
import iconCheckOff from "~/components/icons/check-off";
import iconRadioOn from "~/components/icons/radio-on";
import iconRadioOff from "~/components/icons/radio-off";
import iconNewDomain from "~/components/icons/newdomain";
import iconLogout from "~/components/icons/logout";
import iconSettings from "~/components/icons/settings";
import iconBilling from "~/components/icons/billing";
import iconPerson from "~/components/icons/person";
import iconBriefcase from "~/components/icons/briefcase";
import iconVerticalDots from "~/components/icons/vertical-dots";

import { createVuetify, type ThemeDefinition } from 'vuetify';
import { defineNuxtPlugin } from '#app';

const light: ThemeDefinition = {
    dark: false,
    colors: {
        'primary': '#175CFF', /* override vuetify --v-theme-primary, used by .text-primary */

        'primary-blue': '#175CFF',
        'secondary-blue': '#00AAFF',
        'primary-green': '#00C524',

        'dark-grey': '#66686D',
        'grey': '#A1A6B0',
        'medium-grey': '#B4B9BD',
        'light-grey': '#ECEDEF', /*F6F6F7*/

        'success': '#00C524',
        'warning': '#F7B466',
        'error': '#F85D55',

        'background': '#FBFCFC',
        'anchor': '#3965FA'
    }
}

export default defineNuxtPlugin((app) => {
    const vuetify = createVuetify({
        defaultAssets: false,
        theme: {
            defaultTheme: 'light',
            themes: {
                light,
            }
        },
        icons: {
            iconfont: 'mdi',
            aliases: {
                arrowright: iconArrowRight,
                magnify: iconMagnify,
                pencil: iconPencil,
                plus: iconPlus,
                plusblue: iconPlusBlue,
                trashbin: iconTrashBin,
                alarmbell: iconAlarmBell,
                chevronleft: iconChevronLeft,
                chevronright: iconChevronRight,
                chevrondown: iconChevronDown,
                projects: iconProjects,
                roundglobe: iconRoundGlobe,
                roundrenew: iconRoundRenew,
                roundcomputer: iconRoundComputer,
                roundemail: iconRoundEmail,
                roundtrashbin: iconRoundTrashbin,
                roundtouchfinger: iconRoundTouchFinger,
                rounddatabase: iconRoundDatabase,
                roundinvoice: iconRoundInvoice,
                roundpencil: iconRoundPencil,
                tickblue: iconTickBlue,
                copy: iconCopy,
                eye: iconEye,
                eyeoff: iconEyeOff,
                folderon: iconFolderOn,
                folderoff: iconFolderOff,
                checkminus: iconCheckMinus,
                checkon: iconCheckOn,
                checkoff: iconCheckOff,
                radioon: iconRadioOn,
                radiooff: iconRadioOff,
                newdomain: iconNewDomain,
                logout: iconLogout,
                settings: iconSettings,
                billing: iconBilling,
                person: iconPerson,
                briefcase: iconBriefcase,
                verticaldots: iconVerticalDots,
            }
        }
    })
    app.vueApp.use(vuetify);
})
