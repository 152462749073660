import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import VirtualServer from './VirtualServer'

export default class VirtualServerBackup extends BaseModel {

    static entity = 'virtualServerBackups'

    static fields() {
        return {
            id: this.uid(),
            target: this.string(''),
            targetId: this.number(),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            virtualServerId: this.number(),
            virtualServer: this.belongsTo(VirtualServer, 'virtualServerId')
        }
    }

    static async apiFetchAll(virtualServerId) {
        const query = gql`
            query getVirtualServerBackups($virtualServerId: Int) {
                virtualServerBackups(virtualServerId: $virtualServerId) {
                    id
                    target
                    targetId
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { virtualServerId: parseInt(virtualServerId) })
        if (res && res.virtualServerBackups) {
            useRepo(this).save(res.virtualServerBackups)
        }
        return res
    }

    static async apiRestore(params) {
        const query = gql`
            mutation restoreVirtualServerBackup($id: Int!, $restoreMethod: RestoreMethod!) {
                restoreVirtualServerBackup(id: $id, restoreMethod: $restoreMethod){
                    id
                    target
                    targetId
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        return await window.$graphQLQuery(query, params)
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createVirtualServerBackup($backup: CreateBackupInput!) {
                createVirtualServerBackup(backup: $backup){
                    id
                    target
                    targetId
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { backup: params })
        if (res && res.createVirtualServerBackup) {

            await useRepo(this).save(res.createVirtualServerBackup)
        }
        return res
    }

    static async apiDelete(backupId) {
        const query = gql`
            mutation deleteVirtualServerBackup($id: Int!) {
                deleteVirtualServerBackup(id: $id){
                    id
                    target
                    targetId
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(backupId) })
        if (res && res.deleteVirtualServerBackup) {
            await useRepo(this).save(res.deleteVirtualServerBackup)
        }
        return res
    }
}
