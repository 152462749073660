<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.0927559" fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#3965FA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.125 12H15.625C14.7272 12 14.0081 12.7272 14.0081 13.625L14 26.625C14 27.5228 14.7191 28.25 15.6169 28.25H25.375C26.2728 28.25 27 27.5228 27 26.625V16.875L22.125 12ZM23.75 25H17.25V23.375H23.75V25ZM23.75 21.75H17.25V20.125H23.75V21.75ZM21.3125 17.6875V13.2188L25.7812 17.6875H21.3125Z" fill="#3965FA"/>
    </svg>
</template>

<script>
export default {
  name: "iconRoundInvoice"
};
</script>
