import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import Domain from './Domain'
import VirtualServer from './VirtualServer'

export default class DomainDnsRecord extends BaseModel {

    static entity = 'domainDnsRecords'

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            type: this.string(''),
            data: this.string(''),
            ttl: this.number(0),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),

            // virtual field, not in graphql schema, not queried, will be populated by plugin automatically
            domainId: this.number(0),
            domain: this.belongsTo(Domain, 'domainId')

        }
    }

    // refresh domain dnsrecords
    static async refresh(domainId = 0, virtualServerId = 0) {
        if (virtualServerId) {
            let virtualServer = useRepo(VirtualServer).find(virtualServerId);
            if (virtualServer && virtualServer.domainId) {
                domainId = virtualServer.domainId
            }
        }
        if (domainId) {
            useRepo(this).where('domainId', domainId).delete()
            DomainDnsRecord.apiFetchAll(domainId)
        }
    }

    static async apiFetchAll(domainId) {
        const query = gql`
            query getDomainDnsRecords($domainId: Int!) {
                domainDnsRecords (domainId: $domainId){
                    id
                    name
                    ttl
                    type
                    data
                    created
                    updated
                    deleted
                    domain{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { domainId: parseInt(domainId) })
        if (res && res.domainDnsRecords) {
            useRepo(this).save(res.domainDnsRecords)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createDomainDnsRecord($domainDnsRecord: CreateDomainDnsRecordInput!) {
                createDomainDnsRecord(domainDnsRecord: $domainDnsRecord){
                    id
                    name
                    ttl
                    type
                    data
                    created
                    updated
                    deleted
                    domain{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createDomainDnsRecord) {

            await useRepo(this).save(res.createDomainDnsRecord)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateDomainDnsRecord($domainDnsRecord: UpdateDomainDnsRecordInput!, $id: Int!) {
                updateDomainDnsRecord(domainDnsRecord: $domainDnsRecord, id: $id){
                    id
                    name
                    ttl
                    type
                    data
                    created
                    updated
                    deleted
                    domain{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateDomainDnsRecord) {
            await useRepo(this).save(res.updateDomainDnsRecord)
        }
        return res
    }

    static async apiDelete(domainDnsRecordId) {
        const query = gql`
            mutation deleteDomainDnsRecord($id: Int!) {
                deleteDomainDnsRecord(id: $id){
                    id
                    name
                    ttl
                    type
                    created
                    updated
                    deleted
                    domain{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: domainDnsRecordId })
        if (res && res.deleteDomainDnsRecord) {
            await useRepo(this).save(res.deleteDomainDnsRecord)
        }
        return res
    }

}
