<template>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.49953 14C9.28746 14 10.0677 13.8448 10.7956 13.5433C11.5236 13.2418 12.185 12.7998 12.7422 12.2427C13.2993 11.6855 13.7413 11.0241 14.0428 10.2961C14.3443 9.56816 14.4995 8.78795 14.4995 8.00002C14.4995 7.21208 14.3443 6.43187 14.0428 5.70391C13.7413 4.97596 13.2993 4.31453 12.7422 3.75737C12.185 3.20022 11.5236 2.75827 10.7956 2.45674C10.0677 2.15521 9.28746 2.00002 8.49953 2.00002C6.90823 2.00002 5.3821 2.63216 4.25689 3.75737C3.13167 4.88259 2.49953 6.40872 2.49953 8.00002C2.49953 9.59131 3.13167 11.1174 4.25689 12.2427C5.3821 13.3679 6.90823 14 8.49953 14ZM14.8195 12.906L18.3995 16.486C18.495 16.5783 18.5711 16.6887 18.6234 16.8108C18.6757 16.9328 18.7032 17.064 18.7043 17.1968C18.7053 17.3296 18.6799 17.4613 18.6296 17.5841C18.5792 17.707 18.5049 17.8186 18.4109 17.9124C18.3169 18.0062 18.2052 18.0804 18.0823 18.1306C17.9594 18.1808 17.8277 18.206 17.6949 18.2048C17.5621 18.2035 17.4309 18.1758 17.309 18.1233C17.187 18.0708 17.0767 17.9946 16.9845 17.899L13.4045 14.319C11.797 15.5669 9.77432 16.1552 7.74828 15.9642C5.72224 15.7732 3.84513 14.8173 2.49908 13.2911C1.15302 11.7648 0.439211 9.78296 0.502952 7.74894C0.566694 5.71492 1.4032 3.78164 2.84217 2.34266C4.28115 0.903684 6.21443 0.0671818 8.24845 0.00344074C10.2825 -0.0603003 12.2643 0.653512 13.7906 1.99957C15.3168 3.34562 16.2727 5.22273 16.4637 7.24877C16.6547 9.27481 16.0664 11.2975 14.8185 12.905L14.8195 12.906Z" fill="#66686D"/>
    </svg>
</template>

<script>
export default {
  name: "iconMagnify"
};
</script>
