import { gql } from 'graphql-request';
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import Team from './Team';

export default class StripePaymentMethod extends BaseModel {

    static entity = 'stripePaymentMethods'

    static fields() {
        return {
            id: this.uid(),
            uuid: this.string(''),
            teamId: this.number(0),
            team: this.belongsTo(Team, 'teamId'),
            cardType: this.string(''),
            cardNumber: this.string(''),
            cardExpires: this.string(''),
            preferred: this.boolean(false),
            stripeClientSecret: this.string(''),
            stripePaymentMethodId: this.string(''),
            stripeCustomerId: this.string(''),
            confirmed: this.string(null),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null)
        }
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createStripePaymentMethod($stripePaymentMethod: CreateStripePaymentMethodInput!) {
                createStripePaymentMethod(stripePaymentMethod: $stripePaymentMethod){
                    id
                    uuid
                    team{
                        id
                    }
                    cardType
                    cardNumber
                    cardExpires
                    preferred
                    stripeClientSecret
                    stripePaymentMethodId
                    stripeCustomerId
                    confirmed
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createStripePaymentMethod) {
            await useRepo(this).save(res.createStripePaymentMethod)
        }
        return res
    }

    static async apiConfirm(uuid) {
        const query = gql`
            mutation confirmStripePaymentMethod($uuid: String!) {
                confirmStripePaymentMethod(uuid: $uuid){
                    id
                    uuid
                    team{
                        id
                    }
                    cardType
                    cardNumber
                    cardExpires
                    preferred
                    stripeClientSecret
                    stripePaymentMethodId
                    stripeCustomerId
                    confirmed
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { 'uuid': uuid })
        if (res && res.confirmStripePaymentMethod) {
            await useRepo(this).save(res.confirmStripePaymentMethod)
        }
        return res
    }

    static async apiDelete(uuid) {
        const query = gql`
            mutation deleteStripePaymentMethod($uuid: String!) {
                deleteStripePaymentMethod(uuid: $uuid){
                    id
                    uuid
                    team{
                        id
                    }
                    cardType
                    cardNumber
                    cardExpires
                    preferred
                    stripeClientSecret
                    stripePaymentMethodId
                    stripeCustomerId
                    confirmed
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { 'uuid': uuid })
        if (res && res.deleteStripePaymentMethod) {
            await useRepo(this).save(res.deleteStripePaymentMethod)
        }
        return res
    }
}
