import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import Package from './Package'
import Project from './Project'
import VpnUser from './VpnUser'
import VpnNetwork from './VpnNetwork'

export default class Vpn extends BaseModel {

    static entity = 'vpns'

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''), // vpn.nodeup.io
            serverVpn: this.string(''),
            serverVpnIp: this.string(''),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            packageId: this.attr(''),
            package: this.belongsTo(Package, 'packageId'),
            projectId: this.number(0),
            project: this.belongsTo(Project, 'projectId'),
            vpnUsers: this.hasMany(VpnUser, 'vpnId'),
            vpnNetworks: this.hasMany(VpnNetwork, 'vpnId'),
        }
    }

    static getItems(params) {
        const vpns = super.getItems(params)
        return vpns.map(v => ({
            ...v,
            vpnUsers: VpnUser.getItems({ where: ['vpnId', parseInt(v.id)] }),
            vpnNetworks: VpnNetwork.getItems({ where: ['vpnId', parseInt(v.id)] })
        }))
    }

    static async apiFetchSingle(vpnId) {
        const query = gql`
            query getVpn($id: Int!) {
                vpn(id: $id) {
                    id
                    name
                    serverVpn
                    serverVpnIp
                    vpnNetworks{
                        id
                        name
                        ip
                        subnet
                        internalNetworks
                        vpn{
                            id
                        }
                    }
                    vpnUsers {
                        id
                        name
                        username
                        forwardAllTraffic
                        created
                        updated
                        deleted
                        vpn{
                            id
                        }
                        vpnNetwork{
                            id
                        }
                    }
                    project {
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(vpnId) })
        if (res && res.vpn) {
            useRepo(this).save(res.vpn)
        }
        return res
    }

    static async apiFetchAll(projectId) {
        if (!projectId) {
            return []
        }
        const query = gql`
            query getVpns($projectId: Int) {
                vpns(projectId: $projectId) {
                    id
                    name
                    serverVpn
                    serverVpnIp
                    vpnNetworks{
                        id
                        name
                        ip
                        subnet
                        internalNetworks
                        vpn{
                            id
                        }
                    }
                    vpnUsers {
                        id
                        name
                        username
                        forwardAllTraffic
                        created
                        updated
                        deleted
                        vpn{
                            id
                        }
                        vpnNetwork{
                            id
                        }
                    }
                    project {
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { projectId: parseInt(projectId) })
        if (res && res.vpns) {
            useRepo(this).save(res.vpns)
        }
        return res
    }
}
