<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.0927559" fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#3965FA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1 13H12.9C11.8503 13 11.0095 13.8503 11.0095 14.9L11 26.3C11 27.3497 11.8503 28.2 12.9 28.2H28.1C29.1497 28.2 30 27.3497 30 26.3V14.9C30 13.8503 29.1497 13 28.1 13ZM28.1 16.8L20.5 21.55L12.9 16.8V14.9L20.5 19.65L28.1 14.9V16.8Z" fill="#3965FA"/>
    </svg>
</template>

<script>
export default {
  name: "iconRoundEmail"
};
</script>
