import { gql } from 'graphql-request';
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import { useStore } from '@/store/index';
import { startAuthentication } from '@simplewebauthn/browser';

export default class Me extends BaseModel {

    static entity = 'me'

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            email: this.string(''),
            personalCode: this.string(''),
            personalCodeCountry: this.string(''),
            hasPasswordSet: this.boolean(null),
            isPasswordAuthEnabled: this.boolean(null),
            isPasskeyAuthEnabled: this.boolean(null),
            isGoogleAuthEnabled: this.boolean(null),
            isIdcodeAuthEnabled: this.boolean(null),
            ip: this.string('')
        }
    }

    static getData() {
        let res = super.getItems();
        return res.length ? res[0] : {};
    }

    static async apiFetchSingle() {
        const query = gql`
            query getMyData {
                me {
                    id
                    name
                    email
                    personalCode
                    personalCodeCountry
                    hasPasswordSet
                    isPasswordAuthEnabled
                    isPasskeyAuthEnabled
                    isGoogleAuthEnabled
                    isIdcodeAuthEnabled
                }
            }
        `
        const res = await window.$graphQLQuery(query, {})
        if (res && res.me) {
            useRepo(this).save(res.me)
        }
        return res
    }

    static async apiFetchMyIp() {
        var meData = useRepo(this).query().first()
        if (meData && meData.ip) {
            return meData.ip
        }
        const query = gql`
            query userIp {
                userIp
            }
        `
        const res = await window.$graphQLQuery(query, {})
        var meData = useRepo(this).query().first()
        useRepo(this).save({ id: meData.id, ip: res.userIp })
        return res.userIp
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateMe($me: UpdateMeInput!) {
                updateMe(project: $me){
                    id
                    name
                    email
                    personalCode
                    personalCodeCountry
                    hasPasswordSet
                    isPasswordAuthEnabled
                    isPasskeyAuthEnabled
                    isGoogleAuthEnabled
                    isIdcodeAuthEnabled
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateMe) {
            await useRepo(this).save(res.updateMe)
        }
        return res
    }

    static async passwordLogin(form) {
        const nuxtApp = useNuxtApp();
        var query = gql`
            mutation loginPassword($email:String!,$password:String!){
                loginPassword(email: $email, password: $password) {
                    token
                    me{
                        id
                        name
                        email
                        personalCode
                        personalCodeCountry
                        hasPasswordSet
                        isPasswordAuthEnabled
                        isPasskeyAuthEnabled
                        isGoogleAuthEnabled
                        isIdcodeAuthEnabled
                    }
                    defaultTeam{
                        id
                    }
                    defaultProject{
                        id
                    }
                }
            }
        `
        const variables = {
            email: form.email,
            password: form.password,
        }
        const isAuthQuery = true
        return await nuxtApp.$graphQLQuery(query, variables, isAuthQuery)
    }

    static async passkeyLogin(form) {
        const nuxtApp = useNuxtApp();
        var query = gql`
            mutation loginPasskeyOptions($email: String){
                loginPasskeyOptions(email: $email)
            }
        `
        const isAuthQuery = true
        const res = await nuxtApp.$graphQLQuery(query, { email: form.email }, isAuthQuery)
        const options = JSON.parse(res.loginPasskeyOptions);

        // we receive id as base64url, browser expects us to decode it back into string
        options.allowCredentials = options.allowCredentials.map(cred => ({
            type: cred.type,
            id: nuxtApp.$base64urlToString(cred.id)
        }));

        try {
            const authResponse = await startAuthentication(options);
            const serializedAuthResponse = JSON.stringify(authResponse);
            const params = {
                email: form.email,
                authPasskey: {
                    credential: serializedAuthResponse,
                }
            }
            const query2 = gql`
                mutation loginPasskey($email:String!, $authPasskey:PasskeyAuthInput!) {
                    loginPasskey(email: $email, authPasskey: $authPasskey){
                        token
                        me{
                            id
                            name
                            email
                            personalCode
                            personalCodeCountry
                            hasPasswordSet
                            isPasswordAuthEnabled
                            isPasskeyAuthEnabled
                            isGoogleAuthEnabled
                            isIdcodeAuthEnabled
                        }
                        defaultTeam{
                            id
                        }
                        defaultProject{
                            id
                        }
                    }
                }
            `
            return await nuxtApp.$graphQLQuery(query2, params, isAuthQuery)
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                nuxtApp.$setGlobalError('Authentication was canceled or timed out. Please try again.')
            } else {
                console.error('Unexpected error:', error);
            }
        }
    }

    static async smartidLogin(form) {
        // 50001029996, 30303039914
        const nuxtApp = useNuxtApp();
        var query = gql`
            mutation loginSmartid($authSmartid:SmartIdAuthInput!){
                loginSmartid(authSmartid: $authSmartid) {
                    verificationCode
                    sessionId
                    personalCode
                    personalCodeCountry
                }
            }
        `
        var variables = {
            authSmartid: {
                personalCode: form.personalCode,
                personalCodeCountry: form.personalCodeCountry,
            }
        }
        const isAuthQuery = true
        const res = await nuxtApp.$graphQLQuery(query, variables, isAuthQuery)
        if (res.loginSmartid.personalCode != form.personalCode || res.loginSmartid.personalCodeCountry != form.personalCodeCountry) {
            console.error('Authentication personal code mismatch!')
            return
        }
        form.smartidPin = res.loginSmartid.verificationCode

        query = gql`
            mutation checkLoginSmartid($sessionId:String!){
                checkLoginSmartid(sessionId: $sessionId) {
                    token
                    me{
                        id
                        name
                        email
                        personalCode
                        personalCodeCountry
                        hasPasswordSet
                        isPasswordAuthEnabled
                        isPasskeyAuthEnabled
                        isGoogleAuthEnabled
                        isIdcodeAuthEnabled
                    }
                    defaultTeam{
                        id
                    }
                    defaultProject{
                        id
                    }
                }
            }
        `
        variables = {
            sessionId: res.loginSmartid.sessionId,
        }
        const res2 = await nuxtApp.$graphQLQuery(query, variables, isAuthQuery)
        form.smartidPin = null
        return res2
    }

    static async mobileidLogin(form) {
        // 60001019906, +37200000766
        // 60001018800, +37200000566
        const nuxtApp = useNuxtApp();
        var query = gql`
            mutation loginMobileid($authMobileid:MobileIdAuthInput!){
                loginMobileid(authMobileid: $authMobileid) {
                    verificationCode
                    sessionId
                    personalCode
                    phoneNumber
                }
            }
        `
        var variables = {
            authMobileid: {
                personalCode: form.personalCode,
                phoneNumber: form.phoneNumber,
            }
        }
        const isAuthQuery = true
        const res = await nuxtApp.$graphQLQuery(query, variables, isAuthQuery)
        if (res.loginMobileid.personalCode != form.personalCode || res.loginMobileid.phoneNumber != form.phoneNumber) {
            console.error('Authentication personal code mismatch!')
            return
        }
        form.mobileidPin = res.loginMobileid.verificationCode

        query = gql`
            mutation checkLoginMobileid($sessionId:String!){
                checkLoginMobileid(sessionId: $sessionId) {
                    token
                    me{
                        id
                        name
                        email
                        personalCode
                        personalCodeCountry
                        hasPasswordSet
                        isPasswordAuthEnabled
                        isPasskeyAuthEnabled
                        isGoogleAuthEnabled
                        isIdcodeAuthEnabled
                    }
                    defaultTeam{
                        id
                    }
                    defaultProject{
                        id
                    }
                }
            }
        `
        variables = {
            sessionId: res.loginMobileid.sessionId,
        }
        const res2 = await nuxtApp.$graphQLQuery(query, variables, isAuthQuery)
        form.mobileidPin = null
        return res2
    }

    static async createGoogleNonce(params) {
        const nuxtApp = useNuxtApp();
        var query = gql`
            mutation createGoogleNonce{
                createGoogleNonce {
                    token
                }
            }
        `
        const isAuthQuery = true
        const res = await nuxtApp.$graphQLQuery(query, params, isAuthQuery)
        console.log(res.createGoogleNonce.token)
        return res.createGoogleNonce.token
    }

    static async createIdcardNonce(params) {
        const nuxtApp = useNuxtApp();
        var query = gql`
            mutation createIdcardNonce($browserSid:String!){
                createIdcardNonce(browserSid: $browserSid) {
                    tokenFull
                    tokenSignable
                }
            }
        `
        const isAuthQuery = true
        const res = await nuxtApp.$graphQLQuery(query, params, isAuthQuery)
        return res
    }

    static async idcardLogin() {
        const store = useStore();
        const nuxtApp = useNuxtApp();
        const browserSid = Array.from(crypto.getRandomValues(new Uint8Array(8)), b => b.toString(16).padStart(2, '0')).join('');
        var res = await this.createIdcardNonce({ browserSid: browserSid })
        const tokenFull = res?.createIdcardNonce?.tokenFull
        const tokenSignable = res?.createIdcardNonce?.tokenSignable
        if (!tokenSignable) {
            store.setGlobalError('ID-card nonce retrieval error.')
        }

        let algorithm, signature, unverifiedCertificate
        try {
            const result = await window.webeid.authenticate(tokenSignable, { lang: 'en' });
            algorithm = result.algorithm
            signature = result.signature
            unverifiedCertificate = result.unverifiedCertificate
        } catch (e) {
            store.setGlobalError('ID-card authentication setup error.')
        }
        if (!algorithm || !signature || !unverifiedCertificate) {
            store.setGlobalError('ID-card authentication error.')
        }

        const params = {
            authIdcard: {
                algorithm: algorithm,
                certificate: unverifiedCertificate,
                signature: signature,
                tokenFull: tokenFull,
                browserSid: browserSid
            }
        }
        var query = gql`
            mutation loginIdcard($authIdcard: IdCardAuthInput!){
                loginIdcard(authIdcard: $authIdcard) {
                    token
                    me{
                        id
                        name
                        email
                        personalCode
                        personalCodeCountry
                        hasPasswordSet
                        isPasswordAuthEnabled
                        isPasskeyAuthEnabled
                        isGoogleAuthEnabled
                        isIdcodeAuthEnabled
                    }
                    defaultTeam{
                        id
                    }
                    defaultProject{
                        id
                    }
                }
            }
        `
        const isAuthQuery = true
        return await nuxtApp.$graphQLQuery(query, params, isAuthQuery)
    }

}