import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import VirtualServer from './VirtualServer'
import VirtualServerDatabaseUser from './VirtualServerDatabaseUser'

export default class VirtualServerDatabase extends BaseModel {

    static entity = 'virtualServerDatabases'

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            virtualServerId: this.number(0),
            virtualServer: this.belongsTo(VirtualServer, 'virtualServerId'),
            //userId: this.attr([]),
            users: this.hasMany(VirtualServerDatabaseUser, 'databaseId')
        }
    }

    static async apiFetchAll(virtualServerId) {
        const query = gql`
            query getVirtualServerDatabases($virtualServerId: Int) {
                virtualServerDatabases(virtualServerId: $virtualServerId) {
                    id
                    name
                    users{
                        id
                        username
                        externalIps
                        created
                        updated
                        deleted
                        database{
                            id
                        }
                    }
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { virtualServerId: parseInt(virtualServerId) })
        if (res && res.virtualServerDatabases) {
            useRepo(this).save(res.virtualServerDatabases)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createVirtualServerDatabase($database: CreateDatabaseInput!) {
                createVirtualServerDatabase(database: $database){
                    id
                    name
                    users{
                        id
                        username
                        externalIps
                        created
                        updated
                        deleted
                        database{
                            id
                        }
                    }
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createVirtualServerDatabase) {

            await useRepo(this).save(res.createVirtualServerDatabase)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateVirtualServer($database: UpdateDatabaseInput!, $id: Int!) {
                updateVirtualServerDatabase(database: $database, id: $id){
                    id
                    name
                    users{
                        id
                        username
                        externalIps
                        created
                        updated
                        deleted
                        database{
                            id
                        }
                    }
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateVirtualServerDatabase) {
            await useRepo(this).save(res.updateVirtualServerDatabase)
        }
        return res
    }

    static async apiDelete(databaseId) {
        const query = gql`
            mutation deleteVirtualServerDatabase($id: Int!) {
                deleteVirtualServerDatabase(id: $id){
                    id
                    name
                    users{
                        id
                        username
                        externalIps
                        created
                        updated
                        deleted
                        database{
                            id
                        }
                    }
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(databaseId) })
        if (res && res.deleteVirtualServerDatabase) {
            await useRepo(this).save(res.deleteVirtualServerDatabase)
        }
        return res
    }
}
