<template>
    <svg width="5" height="18" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.4375 4.875C3.78369 4.875 4.875 3.78369 4.875 2.4375C4.875 1.09131 3.78369 0 2.4375 0C1.09131 0 0 1.09131 0 2.4375C0 3.78369 1.09131 4.875 2.4375 4.875Z" fill="#A1A6B0"/>
        <path d="M2.4375 11.4375C3.78369 11.4375 4.875 10.3462 4.875 9C4.875 7.65381 3.78369 6.5625 2.4375 6.5625C1.09131 6.5625 0 7.65381 0 9C0 10.3462 1.09131 11.4375 2.4375 11.4375Z" fill="#A1A6B0"/>
        <path d="M2.4375 18C3.78369 18 4.875 16.9087 4.875 15.5625C4.875 14.2163 3.78369 13.125 2.4375 13.125C1.09131 13.125 0 14.2163 0 15.5625C0 16.9087 1.09131 18 2.4375 18Z" fill="#A1A6B0"/>
    </svg>
</template>

<script>
export default {
  name: "iconVerticalDots"
};
</script>
