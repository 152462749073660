import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import VirtualServer from './VirtualServer'
import DomainDnsRecord from './DomainDnsRecord'
import Task from './Task'

export default class VirtualServerSubdomain extends BaseModel {

    static entity = 'virtualServerSubdomains'

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            nameIdna: this.string(''),
            phpVersion: this.string(''),
            fullPath: this.string(''),
            redirectUrl: this.string(null),
            redirectCode: this.number(null),
            aliases: this.attr([]),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            virtualServerId: this.number(0),
            virtualServer: this.belongsTo(VirtualServer, 'virtualServerId'),

            tasks: this.morphMany(Task, 'objectId', 'contentType')
        }
    }

    static async apiFetchAll(virtualServerId) {
        const query = gql`
            query getVirtualServerSubdomains($virtualServerId: Int) {
                virtualServerSubdomains(virtualServerId: $virtualServerId) {
                    id
                    name
                    nameIdna
                    phpVersion
                    fullPath
                    redirectUrl
                    redirectCode
                    aliases
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { virtualServerId: parseInt(virtualServerId) })
        if (res && res.virtualServerSubdomains) {
            useRepo(this).save(res.virtualServerSubdomains)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createVirtualServerSubdomain($subdomain: CreateSubdomainInput!) {
                createVirtualServerSubdomain(subdomain: $subdomain){
                    id
                    name
                    nameIdna
                    phpVersion
                    fullPath
                    redirectUrl
                    redirectCode
                    aliases
                    created
                    updated
                    deleted
                    virtualServer{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createVirtualServerSubdomain) {
            await useRepo(this).save(res.createVirtualServerSubdomain)
            DomainDnsRecord.refresh(null, res.createVirtualServerSubdomain.virtualServer.id)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateVirtualServerSubdomain($subdomain: UpdateSubdomainInput!, $id: Int!) {
                updateVirtualServerSubdomain(subdomain: $subdomain, id: $id){
                    id
                    name
                    nameIdna
                    phpVersion
                    fullPath
                    redirectUrl
                    redirectCode
                    aliases
                    created
                    updated
                    deleted
                    virtualServer{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateVirtualServerSubdomain) {
            await useRepo(this).save(res.updateVirtualServerSubdomain)
        }
        return res
    }

    static async apiDelete(subdomainId) {
        const query = gql`
            mutation deleteVirtualServerSubdomain($id: Int!) {
                deleteVirtualServerSubdomain(id: $id){
                    id
                    name
                    nameIdna
                    phpVersion
                    fullPath
                    redirectUrl
                    redirectCode
                    aliases
                    created
                    updated
                    deleted
                    virtualServer{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(subdomainId) })
        if (res && res.deleteVirtualServerSubdomain) {
            DomainDnsRecord.refresh(null, res.deleteVirtualServerSubdomain.virtualServer.id)

            await useRepo(this).save(res.deleteVirtualServerSubdomain)
        }
        return res
    }
}
