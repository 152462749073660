import { defineNuxtPlugin } from '#app';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Import locale if needed
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

export default defineNuxtPlugin((nuxtApp) => {
  // Here you can configure global use of dayjs if needed
  dayjs.locale('en'); // Set default locale

  // https://day.js.org/docs/en/durations/durations
  dayjs.extend(duration)
  dayjs.extend(utc)

  // Making dayjs available globally through the nuxtApp instance
  nuxtApp.provide('dayjs', dayjs);
});
