<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.15 16.3125C19.8118 16.3125 19.4875 16.1742 19.2484 15.9281C19.0093 15.6819 18.875 15.3481 18.875 15V10.7457C18.9241 9.0842 18.394 7.45941 17.3807 6.16513C16.3674 4.87084 14.9377 3.99249 13.35 3.68887V1.875C13.35 1.64294 13.2604 1.42038 13.101 1.25628C12.9416 1.09219 12.7254 1 12.5 1C12.2746 1 12.0584 1.09219 11.899 1.25628C11.7396 1.42038 11.65 1.64294 11.65 1.875V3.68887C10.0623 3.99249 8.63261 4.87084 7.61928 6.16513C6.60596 7.45941 6.07592 9.0842 6.125 10.7457V15C6.125 15.3481 5.99067 15.6819 5.75156 15.9281C5.51245 16.1742 5.18815 16.3125 4.85 16.3125C4.62457 16.3125 4.40837 16.4047 4.24896 16.5688C4.08955 16.7329 4 16.9554 4 17.1875C4 17.4196 4.08955 17.6421 4.24896 17.8062C4.40837 17.9703 4.62457 18.0625 4.85 18.0625H20.15C20.3754 18.0625 20.5916 17.9703 20.751 17.8062C20.9104 17.6421 21 17.4196 21 17.1875C21 16.9554 20.9104 16.7329 20.751 16.5688C20.5916 16.4047 20.3754 16.3125 20.15 16.3125Z" fill="black"/>
        <path d="M14.4006 19.375H10.5994C10.5472 19.3751 10.4969 19.3949 10.458 19.4308C10.4192 19.4666 10.3944 19.5159 10.3886 19.5693C10.3793 19.65 10.3748 19.7312 10.375 19.8125C10.375 20.3927 10.5989 20.9491 10.9974 21.3593C11.3959 21.7695 11.9364 22 12.5 22C13.0636 22 13.6041 21.7695 14.0026 21.3593C14.4011 20.9491 14.625 20.3927 14.625 19.8125C14.6252 19.7312 14.6207 19.65 14.6114 19.5693C14.6059 19.5157 14.5813 19.4662 14.5424 19.4303C14.5034 19.3944 14.4529 19.3747 14.4006 19.375Z" fill="black"/>
    </svg>
</template>

<script>
export default {
  name: "iconAlarmBell"
};
</script>
