import Me from '@/data/models/Me';
import { defineNuxtPlugin, useError } from '#app'
import { useRouter } from 'vue-router';
import { useStore } from '@/store/index';

export default defineNuxtPlugin(nuxtApp => {
    console.log('Init plugins/nodeup-error.js')

    const router = useRouter();
    const store = useStore();

    nuxtApp.vueApp.config.errorHandler = (err, instance, info) => {

        // ****************************
        // Gather error message
        // ****************************
        if (err instanceof Error) {
            // js error
            var errObj = {
                message: err.message,
                name: err.name,
                ...err
            };
            var errStr = JSON.stringify(errObj, null, '  ')
        } else if (Array.isArray(err)) {
            // graphql error
            var errStr = ""
            err.map(e => {
                errStr += info + ": " + e.message;
                if (e.extensions && Array.isArray(e.extensions.validation)) {
                    const validationMessages = e.extensions.validation.map(validationError => validationError.message).join(" ");
                    errStr += " " + validationMessages;
                }
            })
        } else {
            // other error
            var tempErr = {};
            Object.getOwnPropertyNames(err).forEach(key => {
                tempErr[key] = err[key];
            });
            var errStr = JSON.stringify(tempErr, null, '  ')
        }


        // ****************************
        // Sentry error logging
        // ****************************
        if (nuxtApp.$sentry) {
            nuxtApp.$sentry.configureScope(function (scope) {
                scope.setExtra('Error class', info)
                scope.setExtra('Error message', errStr)
                if (process.client) {
                    scope.setExtra('Team ID', localStorage.getItem('teamId'))
                    scope.setExtra('Project ID', localStorage.getItem('projectId'))
                    try {
                        scope.setUser(Me.getData());
                    } catch (error) {
                        scope.setUser(JSON.parse(localStorage.getItem('userData') || "{}"))
                    }
                }

                if (err instanceof Error) {
                    nuxtApp.$sentry.captureException(err);
                } else {
                    nuxtApp.$sentry.captureMessage(errStr);
                }
            })
        } else {
            console.log('Sentry error logging not loaded!')
        }


        // ****************************
        // Manage error (logout + show error)
        // ****************************
        let isErrorHandled = false

        // Permission / Authentication errors
        if (errStr.includes('User is not recognized')) {
            const regex = /User is not recognized:\s+([A-Z]+)\|([A-Z]{2})\|([A-Z0-9-]+)\|([^|]*)/;
            const match = errStr.match(regex);
            if (match) {
                const loginMethod = match[1];
                const personalCodeCountry = match[2];
                const personalCode = match[3];
                const personName = match[4];

                // ask for name + email
                router.push({
                    path: '/signup', query: {
                        login_method: loginMethod,
                        personal_code_country: personalCodeCountry,
                        personal_code: personalCode,
                        person_name: personName
                    }
                });
            }
            return

        } else if (errStr.includes('You do not have permission to perform this action') || errStr.includes('JWT token has expired') || errStr.includes('Invalid JWT token.') || errStr.includes('User email is not confirmed')) {
            console.log(errStr + ', logging out!');
            store.beforeLogout()
            window.isAuthenticating = false
            router.push('/login')
            if (errStr.includes('JWT token has expired')) {
                store.setGlobalError('Your session has expired, please relogin.')
            } else {
                store.setGlobalError(err[0].message || 'Unknown error')
            }
            return
        }

        if (typeof err === 'object' && err !== null && 'message' in err && err.message == 'Network request failed') {
            store.setGlobalError('Cannot reach API. Please check your network connection.')
            isErrorHandled = true
        }

        if (err.length) {
            err.forEach(e => {
                // TYPE 1 parameter validation error
                if ('extensions' in e && 'validationErrors' in e.extensions) {
                    e.extensions.validationErrors.forEach(ev => {
                        // set error into store.sidebarErrors
                        store.setSidebarError(ev.message || 'Unknown error')
                        isErrorHandled = true
                    })

                } else if ('extensions' in e && 'validation' in e.extensions) {
                    // TYPE 2 validation error v2
                    e.extensions.validation.forEach(ev => {
                        if (ev.field_name) {
                            // set error into store.sidebarInlineErrors
                            store.setSidebarInlineError({ message: ev.message || 'Unknown error', name: ev.field_name.split('[')[0], path: ev.path })
                        } else {
                            // set error into store.sidebarErrors
                            store.setSidebarError(ev.message || 'Unknown error')
                        }
                        isErrorHandled = true
                    })

                } else {
                    // TYPE 3 generic error (graphql error or stripe error)
                    store.setGlobalError(e.message || 'Unknown error')
                    isErrorHandled = true
                }

            })
        }

        // TYPE 4 unhandled error - show regular nuxt error page
        if (!isErrorHandled) {
            console.log('Unhandled error, showing nuxt error.', err)
            useError(err)

            // Log to console
            if (['dev', 'staging', 'production'].includes(nuxtApp.$config.public.environment)) {
                console.error(err.message || err)
            } else {
                console.error(err)
            }
        }
    }

    nuxtApp.provide('setSidebarInlineError', params => {
        store.setSidebarInlineError(params)
    });
    nuxtApp.provide('setSidebarError', params => {
        store.setSidebarError(params)
    });
    nuxtApp.provide('setGlobalError', params => {
        store.setGlobalError(params)
    });
    nuxtApp.provide('clearSidebarInlineError', params => {
        store.clearSidebarInlineError(params)
    });
    nuxtApp.provide('clearSidebarInlineErrorFull', params => {
        store.clearSidebarInlineErrorFull(params)
    });
    nuxtApp.provide('clearSidebarInlineErrors', () => {
        store.clearSidebarInlineErrors()
    });
    nuxtApp.provide('clearSidebarErrors', () => {
        store.clearSidebarErrors()
    });
    nuxtApp.provide('clearGlobalErrors', () => {
        store.clearGlobalErrors()
    });
});
