import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import Vpn from './Vpn'

export default class VpnNetwork extends BaseModel {

    static entity = 'vpnNetworks'

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            ip: this.string(''),
            subnet: this.number(0),
            internalNetworks: this.attr([]),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            vpnId: this.number(0),
            vpn: this.belongsTo(Vpn, 'vpnId'),
        }
    }

    static async apiFetchAll(vpnId) {
        if (!vpnId) {
            return []
        }
        const query = gql`
            query getVpnNetworks($vpnId: Int) {
                vpnNetworks(vpnId: $vpnId) {
                    id
                    name
                    ip
                    subnet
                    internalNetworks
                    vpn{
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { vpnId: parseInt(vpnId) })
        if (res && res.vpnNetworks) {
            useRepo(this).save(res.vpnNetworks)
        }
        return res
    }
}
