<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.329 11.885L2.12 19.092C2.02775 19.1875 1.91741 19.2637 1.7954 19.3161C1.6734 19.3685 1.54218 19.3961 1.4094 19.3973C1.27662 19.3984 1.14494 19.3731 1.02205 19.3228C0.89915 19.2725 0.787498 19.1983 0.693605 19.1044C0.599712 19.0105 0.525459 18.8989 0.475178 18.776C0.424897 18.6531 0.399596 18.5214 0.40075 18.3886C0.401903 18.2558 0.42949 18.1246 0.481899 18.0026C0.534308 17.8806 0.61049 17.7703 0.706 17.678L8.03 10.354C7.97299 10.0365 7.99362 9.70991 8.09014 9.40211C8.18666 9.0943 8.3562 8.81441 8.5843 8.58631C8.8124 8.3582 9.09229 8.18866 9.4001 8.09214C9.70791 7.99562 10.0345 7.97499 10.352 8.032L17.679 0.706005C17.8676 0.523847 18.1202 0.423052 18.3824 0.425331C18.6446 0.427609 18.8954 0.532778 19.0808 0.718186C19.2662 0.903594 19.3714 1.15441 19.3737 1.4166C19.376 1.6788 19.2752 1.9314 19.093 2.12L11.885 9.33C12.0123 9.68657 12.0358 10.072 11.9527 10.4413C11.8696 10.8107 11.6834 11.1489 11.4156 11.4166C11.1479 11.6844 10.8097 11.8706 10.4403 11.9537C10.0709 12.0368 9.68557 12.0133 9.329 11.886V11.885ZM16.869 5.758C18.75 6.842 20 8.34 20 10C20 13.314 15.042 15.993 10 16C8.97397 15.9999 7.95075 15.8927 6.947 15.68L8.808 13.82C9.5048 14.0377 10.2479 14.0606 10.9568 13.8862C11.6657 13.7118 12.3133 13.3467 12.8295 12.8305C13.3457 12.3143 13.7108 11.6667 13.8852 10.9578C14.0596 10.2489 14.0367 9.5058 13.819 8.809L16.869 5.758ZM12.709 4.262L10.875 6.096C10.217 5.9483 9.53234 5.96934 8.88466 6.15719C8.23697 6.34504 7.6473 6.69359 7.17044 7.17045C6.69359 7.6473 6.34504 8.23698 6.15719 8.88466C5.96934 9.53234 5.94829 10.217 6.096 10.875L2.869 14.1C1.134 13.028 0 11.585 0 10C0 6.686 4.984 3.983 10 4C10.914 4.003 11.827 4.094 12.709 4.262Z" fill="#66686D"/>
    </svg>
</template>
<script>
export default {
  name: "iconEyeOff"
}
</script>
