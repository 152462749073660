import { defineNuxtPlugin } from '#app';
import { useStore } from '@/store/index';

export default defineNuxtPlugin(nuxtApp => {
    console.log('Init plugins/nodeup-reload.js')

    const store = useStore();

    if (!['dev', 'staging', 'production'].includes(nuxtApp.$config.public.environment)) return

    let currentVersion = "";
    const detectVersion = async function () {
        try {
            const response = await fetch('/version.txt', { cache: "no-store" });
            const data = await response.text();
            if (currentVersion && currentVersion !== data) {
                store.set({ 'hasNewVersion': true });
            }
            currentVersion = data;
        } catch (error) {
            console.error("Error fetching version:", error);
        }
    };

    detectVersion();

    function handleVisibilityChange() {
        if (document.visibilityState !== "hidden") {
            detectVersion();
        }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange, false);

    nuxtApp.hook('app:destroyed', () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
    });

});
