import { gql } from 'graphql-request'
import { defineNuxtPlugin } from '#app'
import { useStore } from '@/store/index';
import { useRouter } from 'vue-router';

export default defineNuxtPlugin(nuxtApp => {
    console.log('Init plugins/nodeup-auth.js')

    const router = useRouter();
    const store = useStore();

    if (!window.$graphQLQuery) {
        console.error('GraphQLClient (plugins/graphql-request.js) has to be initialized before auth plugin.')
    }

    const recallRefreshAuthToken = function () {
        // cancel any active setTimeout call
        if (window.refreshAuthTokenTimeout) {
            clearTimeout(window.refreshAuthTokenTimeout)
        }
        window.refreshAuthTokenTimeout = setTimeout(function () { refreshAuthToken() }, nuxtApp.$config.public.refreshAuthTokenInterval)
    }
    // we need this before this plugin has completed loading, no time to wait for injected window.$nuxt.$recallRefreshAuthToken
    window.$recallRefreshAuthToken = recallRefreshAuthToken

    let lastRefreshTime = 0;
    const refreshAuthToken = async function () {
        const currentTime = Date.now();
        if (currentTime - lastRefreshTime < nuxtApp.$config.public.refreshAuthTokenInterval) {
            return
        }
        lastRefreshTime = currentTime;

        var authToken = localStorage.getItem('authToken')
        // validate current token
        if (authToken) {
            const query = gql`
                mutation refresh($token:String!){
                    refresh(token: $token) {
                        token
                    }
                }
            `
            const isAuthQuery = true
            window.isAuthenticating = true
            var res = await window.$graphQLQuery(query, { token: authToken }, isAuthQuery)
            // successful
            if (res && res.refresh && res.refresh.token) {
                // on page reload
                if (!store.isLoggedIn) {
                    console.log("Authentication token received")
                    await store.afterLogin({ authToken: res.refresh.token })

                } else {
                    // on refresh token - lighter version of afterLogin()
                    console.log("Re-authentication token received")
                    window.$setAuthToken(res.refresh.token)
                    localStorage.setItem('authToken', res.refresh.token) // storing authToken for page reloads
                    window.$recallRefreshAuthToken()
                }
                window.isAuthenticating = false

            } else {
                // failed
                store.beforeLogout()
                window.isAuthenticating = false
                router.push('/login')
            }

        } else {
            // needs to re-login, logout
            store.beforeLogout()
            window.isAuthenticating = false
            router.push('/login')
        }
    }

    // run on page load, refresh existing token
    refreshAuthToken()

    // refresh session / logout after returning from computer sleep
    function handleVisibilityChange() {
        if (document.visibilityState !== "hidden") {
            refreshAuthToken()
        }
    }
    document.addEventListener("visibilitychange", handleVisibilityChange, false)
})
