import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import VirtualServer from './VirtualServer'
import VirtualServerEmailListMember from './VirtualServerEmailListMember'

export default class VirtualServerEmailList extends BaseModel {

    static entity = 'virtualServerEmailLists'

    static fields() {
        return {
            id: this.uid(),
            address: this.string(''),
            active: this.boolean(false),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            virtualServerId: this.number(0),
            virtualServer: this.belongsTo(VirtualServer, 'virtualServerId'),
            members: this.hasMany(VirtualServerEmailListMember, 'emailListId')
        }
    }

    static async apiFetchAll(virtualServerId) {
        const query = gql`
            query getVirtualServerEmailLists($virtualServerId: Int) {
                virtualServerEmailLists(virtualServerId: $virtualServerId) {
                    id
                    address
                    active
                    members{
                        id
                        address
                        name
                        emailList{
                            id
                        }
                        created
                        updated
                        deleted
                    }
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { virtualServerId: parseInt(virtualServerId) })
        if (res && res.virtualServerEmailLists) {
            useRepo(this).save(res.virtualServerEmailLists)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createVirtualServerEmailList($emailList: CreateEmailListInput!) {
                createVirtualServerEmailList(emailList: $emailList){
                    id
                    address
                    active
                    members{
                        id
                        address
                        name
                        emailList{
                            id
                        }
                        created
                        updated
                        deleted
                    }
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createVirtualServerEmailList) {

            await useRepo(this).save(res.createVirtualServerEmailList)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateVirtualServerEmailList($emailList: UpdateEmailListInput!, $id:Int!) {
                updateVirtualServerEmailList(emailList: $emailList, id: $id){
                    id
                    address
                    active
                    members{
                        id
                        address
                        name
                        emailList{
                            id
                        }
                        created
                        updated
                        deleted
                    }
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateVirtualServerEmailList) {
            await useRepo(this).save(res.updateVirtualServerEmailList)
        }
        return res
    }

    static async apiDelete(emailListId) {
        const query = gql`
            mutation deleteVirtualServerEmailList($id: Int!) {
                deleteVirtualServerEmailList(id: $id){
                    id
                    address
                    active
                    members{
                        id
                        address
                        name
                        emailList{
                            id
                        }
                        created
                        updated
                        deleted
                    }
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(emailListId) })
        if (res && res.deleteVirtualServerEmailList) {
            await useRepo(this).save(res.deleteVirtualServerEmailList)
        }
        return res
    }
}
