<template>
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.229309 0.229331C0.0825282 0.376082 4.43258e-05 0.575133 0 0.782701V9.40416C0 9.61174 0.0824549 9.81082 0.229225 9.95761C0.375996 10.1044 0.57506 10.1869 0.782625 10.1869H7.04362C7.25119 10.1869 7.45025 10.1044 7.59702 9.95761C7.74379 9.81082 7.82625 9.61174 7.82625 9.40416V0.794442C7.82625 0.586857 7.74379 0.387773 7.59702 0.240988C7.45025 0.0942035 7.25119 0.0117405 7.04362 0.0117405L0.782625 0C0.575077 4.43302e-05 0.376045 0.0825364 0.229309 0.229331Z" fill="#A1A6B0"/>
        <path d="M17.2174 0L10.9565 0.0109578C10.7489 0.0109578 10.5498 0.0934208 10.4031 0.240206C10.2563 0.386991 10.1738 0.586074 10.1738 0.793659V4.70717C10.1738 4.91475 10.2563 5.11383 10.4031 5.26062C10.5498 5.4074 10.7489 5.48987 10.9565 5.48987H17.2174C17.425 5.48987 17.6241 5.4074 17.7708 5.26062C17.9176 5.11383 18.0001 4.91475 18.0001 4.70717V0.782701C18.0001 0.575116 17.9176 0.376033 17.7708 0.229248C17.6241 0.0824629 17.425 0 17.2174 0Z" fill="#A1A6B0"/>
        <path d="M7.59694 17.7838C7.74372 17.637 7.8262 17.438 7.82625 17.2304V13.3169C7.82625 13.1093 7.74379 12.9102 7.59702 12.7634C7.45025 12.6166 7.25119 12.5342 7.04362 12.5342H0.782625C0.57506 12.5342 0.375996 12.6166 0.229225 12.7634C0.0824549 12.9102 0 13.1093 0 13.3169V17.2186C0 17.4262 0.0824549 17.6253 0.229225 17.7721C0.375996 17.9189 0.57506 18.0013 0.782625 18.0013L7.04362 18.0123C7.25106 18.0125 7.45007 17.9303 7.59694 17.7838Z" fill="#A1A6B0"/>
        <path d="M17.2172 7.83887H10.9562C10.7486 7.83887 10.5496 7.92133 10.4028 8.06811C10.256 8.2149 10.1736 8.41398 10.1736 8.62157V17.2313C10.1736 17.4389 10.256 17.638 10.4028 17.7847C10.5496 17.9315 10.7486 18.014 10.9562 18.014L17.2172 18.003C17.4248 18.003 17.6238 17.9206 17.7706 17.7738C17.9174 17.627 17.9998 17.4279 17.9998 17.2203V8.62157C17.9998 8.41398 17.9174 8.2149 17.7706 8.06811C17.6238 7.92133 17.4248 7.83887 17.2172 7.83887Z" fill="#A1A6B0"/>
    </svg>
</template>

<script>
export default {
  name: "iconProjects"
};
</script>
