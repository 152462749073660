import { defineNuxtPlugin } from '#app'
import { reactive } from 'vue';

export default defineNuxtPlugin(nuxtApp => {
    const state = reactive({
        isMobile: false,
    });

    function updateIsMobile() {
        state.isMobile = window.innerWidth < 821;
    }

    updateIsMobile();

    if (process.client) {
        window.addEventListener('resize', updateIsMobile, { passive: true });
        window.addEventListener('orientationchange', () => {
            setTimeout(updateIsMobile, 100);
            setTimeout(updateIsMobile, 150);
        }, { passive: true });

        nuxtApp.hook('app:beforeUnmount', () => {
            window.removeEventListener('resize', updateIsMobile);
            window.removeEventListener('orientationchange', updateIsMobile);
        });
    }

    nuxtApp.provide('isMobile', () => state.isMobile);
});
