<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.87608 8.04775L5.57608 11.3478C5.51241 11.4093 5.46162 11.4828 5.42668 11.5642C5.39174 11.6455 5.37335 11.733 5.37258 11.8215C5.37181 11.91 5.38868 11.9978 5.4222 12.0797C5.45572 12.1617 5.50523 12.2361 5.56782 12.2987C5.63042 12.3613 5.70485 12.4108 5.78678 12.4443C5.86871 12.4778 5.9565 12.4947 6.04502 12.4939C6.13354 12.4932 6.22102 12.4748 6.30235 12.4398C6.38369 12.4049 6.45725 12.3541 6.51875 12.2904L10.2901 8.51909C10.4151 8.39407 10.4853 8.22453 10.4853 8.04775C10.4853 7.87098 10.4151 7.70144 10.2901 7.57642L6.51875 3.80509C6.45725 3.74141 6.38369 3.69063 6.30235 3.65569C6.22102 3.62075 6.13354 3.60236 6.04502 3.60159C5.9565 3.60082 5.86871 3.61769 5.78678 3.65121C5.70485 3.68473 5.63042 3.73423 5.56782 3.79682C5.50523 3.85942 5.45572 3.93385 5.4222 4.01579C5.38868 4.09772 5.37181 4.1855 5.37258 4.27402C5.37335 4.36254 5.39174 4.45002 5.42668 4.53136C5.46162 4.61269 5.51241 4.68626 5.57608 4.74775L8.87608 8.04775Z" fill="#B4B9BD"/>
    </svg>
</template>

<script>
export default {
  name: "iconChevronRight"
};
</script>
