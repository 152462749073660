<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1268 4.85636L10.9872 1.71756L12.2436 0.462038C12.803 -0.0973519 14.0842 -0.208342 14.7546 0.462038L15.3824 1.0898C16.0528 1.76018 15.9426 3.04145 15.3824 3.60172L14.1268 4.85636ZM12.8713 6.11276L4.08089 14.9032L0 15.8444L0.941198 11.7635L9.73163 2.97308L12.8713 6.11276Z" fill="#A1A6B0"/>
    </svg>
</template>

<script>
export default {
  name: "iconPencil"
};
</script>
