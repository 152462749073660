import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import Domain from './Domain'

export default class DomainContact extends BaseModel {

    static entity = 'domainContacts'

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            email: this.string(''),
            phone: this.string(''),
            role: this.string(''),
            identType: this.string(''),
            identCode: this.string(''),
            identCountryCode: this.string(''),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),

            // virtual field, not in graphql schema, not queried, will be populated by plugin automatically
            domainId: this.number(0),
            domain: this.belongsTo(Domain, 'domainId')

        }
    }

    static async apiFetchAll(domainId) {
        const query = gql`
            query getDomainContacts($domainId: Int!) {
                domainContacts (domainId: $domainId){
                    id
                    role
                    identType
                    identCode
                    identCountryCode
                    name
                    email
                    phone
                    created
                    updated
                    deleted
                    domain{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { domainId: parseInt(domainId) })
        if (res && res.domainContacts) {
            useRepo(this).save(res.domainContacts)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createDomainContact($domainContact: CreateDomainContactInput!) {
                createDomainContact(domainContact: $domainContact){
                    id
                    role
                    identType
                    identCode
                    identCountryCode
                    name
                    email
                    phone
                    created
                    updated
                    deleted
                    domain{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createDomainContact) {

            await useRepo(this).save(res.createDomainContact)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateDomainContact($id: Int!, $domainContact: UpdateDomainContactInput!) {
                updateDomainContact(id: $id, domainContact: $domainContact){
                    id
                    role
                    identType
                    identCode
                    identCountryCode
                    name
                    email
                    phone
                    created
                    updated
                    deleted
                    domain{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateDomainContact) {
            await useRepo(this).save(res.updateDomainContact)
        }
        return res
    }

    static async apiDelete(domainContactId) {
        const query = gql`
            mutation deleteDomainContact($id: Int!) {
                deleteDomainContact(id: $id){
                    id
                    role
                    identType
                    identCode
                    identCountryCode
                    name
                    email
                    phone
                    created
                    updated
                    deleted
                    domain{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: domainContactId })
        if (res && res.deleteDomainContact) {
            await useRepo(this).save(res.deleteDomainContact)
        }
        return res
    }
}
