<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 0.5H17C18.3807 0.5 19.5 1.61929 19.5 3V17C19.5 18.3807 18.3807 19.5 17 19.5H3C1.61929 19.5 0.5 18.3807 0.5 17V3C0.5 1.61929 1.61929 0.5 3 0.5Z" stroke="#B4B9BD"/>
    </svg>
</template>
<script>
export default {
  name: "iconCheckOff"
}
</script>
