<template>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.27273 7.27273C9.28182 7.27273 10.9091 5.64091 10.9091 3.63636C10.9091 1.62727 9.28182 0 7.27273 0C5.26364 0 3.63636 1.62727 3.63636 3.63636C3.63636 5.64091 5.26364 7.27273 7.27273 7.27273ZM7.27273 9.09091C4.85 9.09091 0 10.3045 0 12.7273V14.5455H14.5455V12.7273C14.5455 10.3045 9.69545 9.09091 7.27273 9.09091Z" fill="#3965FA"/>
    </svg>
</template>

<script>
export default {
  name: "iconPerson"
};
</script>
