import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useStore } from '@/store/index';
import { startRegistration } from '@simplewebauthn/browser';
import Me from '@/data/models/Me'

export default class User extends BaseModel {

    static entity = 'users'

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            email: this.string(''),
        }
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateUser($user: UpdateUserInput!) {
                updateUser(user: $user){
                    id
                }
            }
        `
        const isAuthQuery = true
        const res = await window.$graphQLQuery(query, params, isAuthQuery)
        await Me.apiFetchSingle()
        return res
    }

    static async apiSignupPassword(form) {
        const nuxtApp = useNuxtApp();
        const params = {
            user: {
                name: form.name,
                email: form.email,
                authMethod: 'PASSWORD',
                passwordAuth: {
                    password: form.password,
                    passwordRepeat: form.passwordRepeat,
                }
            }
        }
        const query = gql`
            mutation signupPassword($user: CreateUserInput!) {
                signupPassword(user: $user){
                    id
                }
            }
        `
        const isAuthQuery = true
        return await nuxtApp.$graphQLQuery(query, params, isAuthQuery)
    }

    static async apiSetPasskey(form) {
        const nuxtApp = useNuxtApp();
        const store = useStore();

        const query = gql`
            mutation($email: String!) {
                signupPasskeyOptions(email: $email)
            }
        `
        const isAuthQuery = true
        const res = await nuxtApp.$graphQLQuery(query, { email: form.email }, isAuthQuery)
        const options = JSON.parse(res.signupPasskeyOptions);
        const registrationOptions = {
            challenge: options.challenge,
            rp: {
                id: options.rp.id,
                name: options.rp.name
            },
            user: {
                id: "1234",
                name: form.name,
                displayName: form.name
            },
            pubKeyCredParams: options.pubKeyCredParams,
            timeout: options.timeout,
            excludeCredentials: options.excludeCredentials.map(cred => ({
                type: cred.type,
                id: "1234"
            })),
            authenticatorSelection: options.authenticatorSelection,
            attestation: options.attestation,
            extensions: options.extensions
        };
        try {
            const attResp = await startRegistration(registrationOptions);
            return JSON.stringify(attResp);
        } catch (e) {
            store.setGlobalError('Passkey registration setup error.')
            return false
        }
    }

    static async apiSignupPasskey(form) {
        const nuxtApp = useNuxtApp();
        const isAuthQuery = true
        const credential = await this.apiSetPasskey(form)
        if (!credential) {
            return false
        }
        const params = {
            user: {
                name: form.name,
                email: form.email,
                authMethod: 'PASSKEY',
                passkeyAuth: {
                    credential: credential
                }
            }
        }
        const query = gql`
            mutation signupPasskey($user: CreateUserInput!) {
                signupPasskey(user: $user){
                    id
                }
            }
        `
        return await nuxtApp.$graphQLQuery(query, params, isAuthQuery)
    }

    static async apiSignupIdcard(form) {
        const store = useStore();
        const nuxtApp = useNuxtApp();
        const browserSid = Array.from(crypto.getRandomValues(new Uint8Array(8)), b => b.toString(16).padStart(2, '0')).join('');
        const res = await Me.createIdcardNonce({ browserSid: browserSid })
        const tokenFull = res?.createIdcardNonce?.tokenFull
        const tokenSignable = res?.createIdcardNonce?.tokenSignable
        if (!tokenSignable) {
            store.setGlobalError('ID-card nonce retrieval error.')
        }

        let algorithm, signature, unverifiedCertificate
        try {
            const result = await window.webeid.authenticate(tokenSignable, { lang: 'en' });
            algorithm = result.algorithm
            signature = result.signature
            unverifiedCertificate = result.unverifiedCertificate
        } catch (e) {
            store.setGlobalError('ID-card authentication setup error.')
        }
        if (!algorithm || !signature || !unverifiedCertificate) {
            store.setGlobalError('ID-card authentication error.')
        }
        const params = {
            user: {
                name: form.name,
                email: form.email,
                authMethod: 'IDCARD',
                idcardAuth: {
                    algorithm: algorithm,
                    certificate: unverifiedCertificate,
                    signature: signature,
                    tokenFull: tokenFull,
                    browserSid: browserSid
                }
            }
        }
        const query = gql`
            mutation signupIdcard($user: CreateUserInput!) {
                signupIdcard(user: $user){
                    id
                }
            }
        `
        const isAuthQuery = true
        return await nuxtApp.$graphQLQuery(query, params, isAuthQuery)
    }

    static async apiSignupSmartid(form) {
        var params = {
            user: {
                name: form.name,
                email: form.email,
                authMethod: 'SMARTID',
                smartidAuth: {
                    personalCode: form.personalCode,
                    personalCodeCountry: form.personalCodeCountry,
                }
            }
        }
        var query = gql`
            mutation signupSmartid($user: CreateUserInput!) {
                signupSmartid(user: $user){
                    verificationCode
                    sessionId
                    personalCode
                    personalCodeCountry
                }
            }
        `
        const isAuthQuery = true
        const res = await window.$graphQLQuery(query, params, isAuthQuery)
        if (!res?.signupSmartid) {
            return
        }
        if (res.signupSmartid.personalCode != form.personalCode || res.signupSmartid.personalCodeCountry != form.personalCodeCountry) {
            console.error('Authentication personal code mismatch!')
            return
        }
        form.smartidPin = res.signupSmartid.verificationCode
        params.sessionId = res.signupSmartid.sessionId
        query = gql`
            mutation checkSignupSmartid($sessionId:String!, $user: CreateUserInput!){
                checkSignupSmartid(sessionId: $sessionId, user: $user) {
                    id
                }
            }
        `
        const res2 = await window.$graphQLQuery(query, params, isAuthQuery)
        form.smartidPin = null
        return res2
    }

    static async apiSignupMobileid(form) {
        var params = {
            user: {
                name: form.name,
                email: form.email,
                authMethod: 'MOBILEID',
                mobileidAuth: {
                    personalCode: form.personalCode,
                    phoneNumber: form.phoneNumber,
                }
            }
        }
        var query = gql`
            mutation signupMobileid($user: CreateUserInput!) {
                signupMobileid(user: $user){
                    verificationCode
                    sessionId
                    personalCode
                    phoneNumber
                }
            }
        `
        const isAuthQuery = true
        const res = await window.$graphQLQuery(query, params, isAuthQuery)
        if (!res?.signupMobileid) {
            return
        }
        if (res.signupMobileid.personalCode != form.personalCode || res.signupMobileid.phoneNumber != form.phoneNumber) {
            console.error('Authentication personal code or phone number mismatch!')
            return
        }
        form.mobileidPin = res.signupMobileid.verificationCode
        params.sessionId = res.signupMobileid.sessionId
        query = gql`
            mutation checkSignupMobileid($sessionId:String!, $user: CreateUserInput!){
                checkSignupMobileid(sessionId: $sessionId, user: $user) {
                    id
                }
            }
        `
        const res2 = await window.$graphQLQuery(query, params, isAuthQuery)
        form.mobileidPin = null
        return res2
    }

    static async apiConfirmEmail(params) {
        const query = gql`
            mutation confirmUserEmail($confirmUserEmail: ConfirmEmailInput!) {
                confirmUserEmail(confirmUserEmail: $confirmUserEmail)
            }
        `
        const isAuthQuery = true
        return await window.$graphQLQuery(query, { 'confirmUserEmail': params }, isAuthQuery)
    }

    static async apiForgotUserPassword(params) {
        const query = gql`
            mutation forgotUserPassword($forgotUserPassword: ForgotUserPasswordInput!) {
                forgotUserPassword(forgotUserPassword: $forgotUserPassword)
            }
        `
        const isAuthQuery = true
        return await window.$graphQLQuery(query, { 'forgotUserPassword': params }, isAuthQuery)
    }

    static async apiChangeUserPassword(params) {
        const query = gql`
            mutation changeUserPassword($changeUserPassword: ChangeUserPasswordInput!) {
                changeUserPassword(changeUserPassword: $changeUserPassword)
            }
        `
        const isAuthQuery = true
        return await window.$graphQLQuery(query, { 'changeUserPassword': params }, isAuthQuery)
    }
}
