import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import Team from './Team'
import Domain from './Domain'
import VirtualServer from './VirtualServer'

export default class Project extends BaseModel {

    static entity = 'projects'
    static eagerLoad = ['team'];

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            color: this.string(''),
            description: this.string(''),
            created: this.string(''),
            teamId: this.number(0), // virtual field
            team: this.belongsTo(Team, 'teamId'),
            domains: this.hasMany(Domain, 'projectId'),
            virtualServers: this.hasMany(VirtualServer, 'projectId'),
        }
    }

    static async apiFetchSingle(projectId) {
        // no existing projects
        if (projectId == 0) return {}

        const query = gql`
            query project ($id: Int!) {
                project(id: $id) {
                    created
                    id
                    name
                    color
                    description
                    team {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(projectId) })
        if (res && res.project) {
            useRepo(this).save(res.project)
        }
        return res
    }

    static async apiFetchAllData(projectId) {
        // no existing projects
        if (projectId == 0) return {}

        const query = gql`
            query project ($id: Int!) {
                project(id: $id) {
                    created
                    id
                    name
                    color
                    description
                    team {
                        id
                    }
                    domains {
                        id
                        name
                        nameIdna
                        registryCreated
                        registryUpdated
                        registryExpires
                        status
                        created
                        updated
                        deleted
                        projectId
                        virtualServerId
                    }
                    virtualServers{
                        id
                        domainName
                        domainNameIdna
                        serverWeb
                        serverWebIp
                        serverEmail
                        serverEmailIp
                        package {
                            code
                            title
                            description
                        }
                        created
                        updated
                        deleted
                        domain{
                            id
                        }
                        project{
                            id
                        }
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(projectId) })
        if (res && res.project) {
            useRepo(this).save(res.project)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createProject($project: CreateProjectInput!) {
                createProject(project: $project){
                    id
                    name
                    color
                    description
                    created
                    team{
                        id
                    }
                    domains{
                        id
                    }
                    virtualServers{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createProject) {

            await useRepo(this).save(res.createProject)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateProject($id: Int!, $project: UpdateProjectInput!) {
                updateProject(id: $id, project: $project){
                    id
                    name
                    color
                    description
                    created
                    team{
                        id
                    }
                    domains{
                        id
                    }
                    virtualServers{
                        id
                    }
                }
            }

        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateProject) {
            await useRepo(this).save(res.updateProject)
        }
        return res
    }


    static async apiDelete(projectId) {
        const query = gql`
            mutation deleteProject($id: Int!) {
                deleteProject(id: $id){
                    id
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: projectId })
        if (res && res.deleteProject) {
            useRepo(this).destroy(res.deleteProject.id)
        }
        return res
    }
}
