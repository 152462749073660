<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.0927559" fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#3965FA"/>
        <path d="M26.1268 16.8564L22.9872 13.7176L24.2436 12.4621C24.803 11.9027 26.0842 11.7917 26.7546 12.4621L27.3824 13.0899C28.0528 13.7602 27.9426 15.0415 27.3824 15.6018L26.1268 16.8564ZM24.8713 18.1128L16.0809 26.9032L12 27.8444L12.9412 23.7635L21.7316 14.9731L24.8713 18.1128Z" fill="#175CFF"/>
    </svg>
</template>

<script>
export default {
  name: "iconRoundPencil"
};
</script>
