<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.0927559" fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#3965FA"/>
        <path d="M14.5655 14.4609C15.5459 13.4867 16.7789 12.7994 18.1287 12.475C19.4784 12.1506 20.8924 12.2016 22.2147 12.6224C22.5038 12.7138 22.8177 12.6878 23.0874 12.55C23.3571 12.4122 23.5605 12.174 23.6528 11.8878C23.7451 11.6015 23.7188 11.2907 23.5796 11.0236C23.4405 10.7566 23.1999 10.5552 22.9108 10.4638C21.1286 9.89914 19.2208 9.849 17.4108 10.3192C15.6008 10.7895 13.9635 11.7607 12.6908 13.119C11.4181 14.4774 10.5626 16.1667 10.2245 17.9891C9.88638 19.8115 10.0797 21.6916 10.7817 23.4092C10.8013 23.4574 10.8034 23.5109 10.7877 23.5605C10.772 23.6101 10.7395 23.6528 10.6956 23.6813L9.41323 24.5176C9.26152 24.6161 9.14276 24.757 9.07202 24.9224C9.00127 25.0878 8.98174 25.2703 9.01588 25.4467C9.05003 25.6232 9.13631 25.7856 9.2638 25.9134C9.39129 26.0412 9.55424 26.1287 9.73199 26.1647L13.7686 26.9882C13.8289 27.0007 13.8903 27.0071 13.9518 27.0072C14.1628 27.0071 14.3673 26.9348 14.5307 26.8026C14.6941 26.6704 14.8064 26.4864 14.8486 26.2817L15.6857 22.2855C15.7222 22.1098 15.7053 21.9274 15.6371 21.7613C15.569 21.5951 15.4526 21.4527 15.3027 21.3521C15.1528 21.2514 14.9762 21.197 14.7951 21.1957C14.614 21.1945 14.4365 21.2464 14.2852 21.3449L13.0588 22.144C13.0292 22.1633 12.9956 22.1756 12.9604 22.1798C12.9253 22.1841 12.8896 22.1802 12.8563 22.1685C12.8229 22.1568 12.7927 22.1376 12.7681 22.1124C12.7435 22.0873 12.7251 22.0568 12.7144 22.0234C12.2948 20.7157 12.2456 19.3192 12.572 17.9858C12.8984 16.6524 13.5879 15.4332 14.5655 14.4609Z" fill="#175CFF"/>
        <path d="M30.7847 14.1571C30.7544 13.9803 30.6717 13.8165 30.5474 13.6862C30.423 13.5559 30.2624 13.4651 30.0859 13.4251L26.0666 12.5181C25.9492 12.4913 25.8276 12.4876 25.7088 12.5074C25.59 12.5272 25.4762 12.57 25.3741 12.6333C25.272 12.6967 25.1835 12.7794 25.1138 12.8766C25.044 12.9739 24.9943 13.0839 24.9675 13.2002L24.0515 17.1791C24.0113 17.354 24.0243 17.5368 24.0889 17.7043C24.1535 17.8719 24.2669 18.0167 24.4146 18.1205C24.5624 18.2243 24.7379 18.2823 24.9189 18.2874C25.1 18.2924 25.2785 18.2441 25.4319 18.1487L26.7536 17.327C26.7827 17.3079 26.8159 17.2958 26.8505 17.2915C26.8852 17.2873 26.9203 17.291 26.9533 17.3025C26.9862 17.3144 27.0159 17.3336 27.0401 17.3586C27.0644 17.3836 27.0826 17.4137 27.0934 17.4467C27.5201 18.7503 27.5771 20.1449 27.2581 21.4784C26.939 22.8118 26.2563 24.0329 25.2843 25.0084C24.3123 25.9838 23.0884 26.6762 21.7461 27.01C20.4039 27.3438 18.9949 27.3061 17.6728 26.9011C17.5279 26.8527 17.3747 26.8335 17.2223 26.8447C17.0698 26.8558 16.9211 26.8971 16.785 26.966C16.6489 27.035 16.5282 27.1302 16.4298 27.2462C16.3315 27.3621 16.2576 27.4963 16.2125 27.641C16.1675 27.7856 16.1521 27.9378 16.1673 28.0884C16.1826 28.239 16.2281 28.3851 16.3013 28.518C16.3745 28.6509 16.4738 28.768 16.5934 28.8623C16.713 28.9566 16.8505 29.0262 16.9977 29.067C18.7749 29.6113 20.6715 29.6459 22.4677 29.1669C24.264 28.6878 25.8864 27.7148 27.1472 26.3603C28.408 25.0059 29.2555 23.3255 29.5916 21.5138C29.9277 19.702 29.7386 17.8331 29.0463 16.1234C29.0272 16.0746 29.0259 16.0207 29.0426 15.971C29.0593 15.9214 29.093 15.879 29.1378 15.8513L30.3707 15.084C30.5251 14.9879 30.6469 14.8483 30.7207 14.6831C30.7945 14.518 30.8168 14.3348 30.7847 14.1571Z" fill="#175CFF"/>
    </svg>
</template>

<script>
export default {
  name: "iconRoundRenew"
};
</script>
