import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import VirtualServer from './VirtualServer'

export default class VirtualServerCronjob extends BaseModel {

    static entity = 'virtualServerCronjobs'

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            target: this.string(''),
            type: this.string(''),
            expression: this.string(''),
            emailSuccess: this.string(''),
            emailFail: this.string(''),
            active: this.boolean(false),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            virtualServerId: this.number(0),
            virtualServer: this.belongsTo(VirtualServer, 'virtualServerId')
        }
    }

    static async apiFetchAll(virtualServerId) {
        const query = gql`
            query getVirtualServerCronjobs($virtualServerId: Int) {
                virtualServerCronjobs(virtualServerId: $virtualServerId) {
                    id
                    name
                    target
                    type
                    expression
                    created
                    updated
                    deleted
                    emailSuccess
                    emailFail
                    active
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { virtualServerId: parseInt(virtualServerId) })
        if (res && res.virtualServerCronjobs) {
            useRepo(this).save(res.virtualServerCronjobs)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createVirtualServerCronjob($cronjob: CreateCronjobInput!) {
                createVirtualServerCronjob(cronjob: $cronjob){
                    id
                    name
                    target
                    type
                    expression
                    created
                    updated
                    deleted
                    emailSuccess
                    emailFail
                    active
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createVirtualServerCronjob) {
            await useRepo(this).save(res.createVirtualServerCronjob)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateVirtualServerCronjob($cronjob: UpdateCronjobInput!, $id: Int!) {
                updateVirtualServerCronjob(cronjob: $cronjob, id: $id){
                    id
                    name
                    target
                    type
                    expression
                    created
                    updated
                    deleted
                    emailSuccess
                    emailFail
                    active
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateVirtualServerCronjob) {
            await useRepo(this).save(res.updateVirtualServerCronjob)
        }
        return res
    }

    static async apiDelete(basicAuthId) {
        const query = gql`
            mutation deleteVirtualServerCronjob($id: Int!) {
                deleteVirtualServerCronjob(id: $id){
                    id
                    name
                    target
                    type
                    expression
                    created
                    updated
                    deleted
                    emailSuccess
                    emailFail
                    active
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(basicAuthId) })
        if (res && res.deleteVirtualServerCronjob) {
            await useRepo(this).save(res.deleteVirtualServerCronjob)
        }
        return res
    }
}
