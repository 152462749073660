<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.0927559" fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#3965FA"/>
        <path d="M13 11.065H27C27.7956 11.065 28.5587 11.3811 29.1213 11.9437C29.6839 12.5063 30 13.2694 30 14.065V23.065C30 23.8607 29.6839 24.6237 29.1213 25.1863C28.5587 25.7489 27.7956 26.065 27 26.065H13C12.2044 26.065 11.4413 25.7489 10.8787 25.1863C10.3161 24.6237 10 23.8607 10 23.065V14.065C10 13.2694 10.3161 12.5063 10.8787 11.9437C11.4413 11.3811 12.2044 11.065 13 11.065ZM17 27.065H23C23.2652 27.065 23.5196 27.1704 23.7071 27.3579C23.8946 27.5454 24 27.7998 24 28.065C24 28.3302 23.8946 28.5846 23.7071 28.7721C23.5196 28.9596 23.2652 29.065 23 29.065H17C16.7348 29.065 16.4804 28.9596 16.2929 28.7721C16.1054 28.5846 16 28.3302 16 28.065C16 27.7998 16.1054 27.5454 16.2929 27.3579C16.4804 27.1704 16.7348 27.065 17 27.065Z" fill="#175CFF"/>
    </svg>
</template>

<script>
export default {
  name: "iconRoundComputer"
};
</script>
