import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import Vpn from './Vpn'
import VpnNetwork from './VpnNetwork'
import Task from './Task'

export default class VpnUser extends BaseModel {

    static entity = 'vpnUsers'

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            username: this.string(''),
            forwardAllTraffic: this.boolean(false),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            vpnId: this.number(0),
            vpn: this.belongsTo(Vpn, 'vpnId'),
            vpnNetworkId: this.number(0),
            vpnNetwork: this.belongsTo(VpnNetwork, 'vpnNetworkId'),

            tasks: this.morphMany(Task, 'objectId', 'contentType')
        }
    }

    static async apiFetchSingle(vpnUserId) {
        const query = gql`
            query getVpnUser($id: Int!) {
                vpnUser(id: $id) {
                    id
                    name
                    username
                    forwardAllTraffic
                    vpn{
                        id
                    }
                    vpnNetwork{
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(vpnUserId) })
        if (res && res.vpnUser) {
            useRepo(this).save(res.vpnUser)
        }
        return res
    }

    static async apiFetchAll(vpnId) {
        if (!vpnId) {
            return []
        }
        const query = gql`
            query getVpnUsers($vpnId: Int) {
                vpnUsers(vpnId: $vpnId) {
                    id
                    name
                    username
                    forwardAllTraffic
                    vpn{
                        id
                    }
                    vpnNetwork{
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { vpnId: parseInt(vpnId) })
        if (res && res.vpnUsers) {
            useRepo(this).save(res.vpnUsers)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createVpnUser($vpnUser: CreateVpnUserInput!) {
                createVpnUser(vpnUser: $vpnUser){
                    id
                    name
                    username
                    forwardAllTraffic
                    vpn{
                        id
                    }
                    vpnNetwork{
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createVpnUser) {
            await useRepo(this).save(res.createVpnUser)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateVpnUser($vpnUser: UpdateVpnUserInput!, $id: Int!) {
                updateVpnUser(vpnUser: $vpnUser, id: $id){
                    id
                    name
                    username
                    forwardAllTraffic
                    vpn{
                        id
                    }
                    vpnNetwork{
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateVpnUser) {
            await useRepo(this).save(res.updateVpnUser)
        }
        return res
    }

    static async apiDelete(virtualServerId) {
        const query = gql`
            mutation deleteVpnUser($id: Int!) {
                deleteVpnUser(id: $id){
                    id
                    name
                    username
                    forwardAllTraffic
                    vpn{
                        id
                    }
                    vpnNetwork{
                        id
                    }
                    created
                    updated
                    deleted
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(virtualServerId) })
        if (res && res.deleteVpnUser) {
            await useRepo(this).save(res.deleteVpnUser)
        }
        return res
    }

    static async apiDownloadOvpnFile(vpnUserId) {
        const query = gql`
            query getOvpnFile($vpnUserId: Int!) {
                ovpnFile(vpnUserId: $vpnUserId)
            }
        `
        return await window.$graphQLQuery(query, { vpnUserId: parseInt(vpnUserId) })
    }

}
