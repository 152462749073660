import { defineNuxtPlugin } from '#app';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

export default defineNuxtPlugin((nuxtApp) => {
    const { vueApp } = nuxtApp;

    Sentry.init({
        app: [vueApp],
        dsn: nuxtApp.$config.public.sentryDsn,
        environment: nuxtApp.$config.public.sentryEnvironment,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router)
            })
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // in case we want to avoid certain events from being sent to Sentry
        // beforeSend(event, hint) {
        //     if (event.exception) {
        //         console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint });
        //     }
        //     // Continue sending to Sentry
        //     return event;
        // }
    });

    vueApp.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }));
    Sentry.attachErrorHandler(vueApp, { logErrors: false, attachProps: true, trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] });

    return {
        provide: {
            sentry: Sentry
        }
    };
});