import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import VirtualServer from './VirtualServer'

export default class VirtualServerFtpAccount extends BaseModel {

    static entity = 'virtualServerFtpAccounts'

    static fields() {
        return {
            id: this.uid(),
            username: this.string(''),
            path: this.string(''),
            active: this.boolean(false),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            virtualServerId: this.number(0),
            virtualServer: this.belongsTo(VirtualServer, 'virtualServerId'),
        }
    }

    static async apiFetchAll(virtualServerId) {
        const query = gql`
            query getVirtualServerFtpAccounts($virtualServerId: Int) {
                virtualServerFtpAccounts(virtualServerId: $virtualServerId) {
                    id
                    username
                    path
                    active
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { virtualServerId: parseInt(virtualServerId) })
        if (res && res.virtualServerFtpAccounts) {
            useRepo(this).save(res.virtualServerFtpAccounts)
        }
        return res
    }

    static async apiFetchSingle(ftpAccountId) {
        const query = gql`
            query getVirtualServerFtpAccount($id: Int!) {
                virtualServerFtpAccount(id: $id) {
                    id
                    username
                    path
                    active
                    created
                    updated
                    deleted
                    virtualServer {
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(ftpAccountId) })
        if (res && res.virtualServerFtpAccount) {
            useRepo(this).save(res.virtualServerFtpAccount)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createVirtualServerFtpAccount($ftpAccount: CreateFtpAccountInput!) {
                createVirtualServerFtpAccount(ftpAccount: $ftpAccount){
                    id
                    username
                    path
                    active
                    created
                    updated
                    deleted
                    virtualServer{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createVirtualServerFtpAccount) {

            await useRepo(this).save(res.createVirtualServerFtpAccount)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateVirtualServerFtpAccount($ftpAccount: UpdateFtpAccountInput!, $id: Int!) {
                updateVirtualServerFtpAccount(ftpAccount: $ftpAccount, id: $id){
                    id
                    username
                    path
                    active
                    created
                    updated
                    deleted
                    virtualServer{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateVirtualServerFtpAccount) {
            await useRepo(this).save(res.updateVirtualServerFtpAccount)
        }
        return res
    }

    static async apiDelete(ftpAccountId) {
        const query = gql`
            mutation deleteVirtualServerFtpAccount($id: Int!) {
                deleteVirtualServerFtpAccount(id: $id){
                    id
                    username
                    path
                    active
                    created
                    updated
                    deleted
                    virtualServer{
                        id
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(ftpAccountId) })
        if (res && res.deleteVirtualServerFtpAccount) {
            await useRepo(this).save(res.deleteVirtualServerFtpAccount)
        }
        return res
    }
}
